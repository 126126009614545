<template>
  <div>
    <span style="font-size: 18px;font-weight: bold;">REGISTRAR PACIENTE</span>
    <el-divider/>
    <el-form
      ref="patientForm" label-widt="120px" :rules="rules"
      :model="patientForm"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="Nombres" prop="firstName">
            <el-input v-model="patientForm.firstName"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Apellidos" prop="lastName">
            <el-input v-model="patientForm.lastName"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Sexo" prop="sex">
            <el-select
              v-model="patientForm.sex"
              placeholder="Seleccionar"
              style="min-width: 100%;"
            >
              <el-option label="Masculino" value="Masculino"/>
              <el-option label="Femenino" value="Femenino"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Fecha de nacimiento" prop="birthdate">
            <el-date-picker
              v-model="patientForm.birthdate"
              type="date"
              placeholder="Seleccione una fecha"
              value-format="yyyy-MM-dd"
              style="min-width: 100%;"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="curp"
            style="padding-right:0%!important;"
            label="CURP"
          >
            <el-input
              v-model="patientForm.curp"
              @input="upper($event)"
              maxlength="18"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="phone" label="Teléfono Fijo">
            <el-input
              v-model="patientForm.phone"
              v-mask="'(999) 999-9999'"
              placeholder="(___) ___-____"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              prop="cellPhone"
              label="Teléfono Particular"
            >
              <el-input
                v-model="patientForm.cellPhone"
                v-mask="'(999) 999-9999'"
                placeholder="(___) ___-____"
              />
            </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="extraPhone" label="Teléfono Extra">
              <el-input
                v-model="patientForm.extraPhone"
                v-mask="'(999) 999-9999'"
                placeholder="(___) ___-____"
              />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item style="display:flex;justify-content:right;">
        <el-button type="danger" @click="cancelar()">Cancelar</el-button>
        <el-button type="primary" @click="onSubmit('patientForm')">Registrar</el-button>
        <!-- <el-button type="primary" @click="enviar()">Enviar</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'createPatient',
  data() {
    return {
      rules: {
        firstName: [{ required: true, message: 'Este campo es requerido', trigger: 'blur' }],
        lastName: [{ required: true, message: 'Este campo es requerido', trigger: 'blur' }],
        sex: [{ required: true, message: 'Este campo es requerido', trigger: 'change' }],
        birthdate: [{ required: true, message: 'Este campo es requerido', trigger: 'change' }],
      },
      patientForm: {
        firstName: '',
        lastName: '',
        sex: '',
        birthdate: '',
        phone: '',
        cellPhone: '',
        extraPhone: '',
        curp: '',
      },
    };
  },
  methods: {
    onSubmit(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) this.existPaitients();
        else return false;
      });
    },
    async registerPatient() {
      const response = await this.axios.post('patient', this.patientForm);
      const { data } = response;
      if (data) {
        const { patient: patientId } = data;
        const message = 'El paciente ha sido registrado';
        await this.axios.post(`patient/changelog/${patientId}`, {
          title: 'Agenda',
          message,
        });
        this.$message({
          message,
          type: 'success',
        });
        this.enviar(patientId);
      }
    },
    async existPaitients() {
      try {
        const {
          firstName,
          lastName,
          birthdate,
        } = this.patientForm;
        const fechaNacimient = birthdate.toString();
        const exists = await this.axios.get(`patient/specialty/${firstName}/${lastName}/${fechaNacimient}`);
        if (exists.data.length > 0) {
          this.$message({
            message: 'El Paciente Ya Se Encuentra Registrado',
            type: 'warning',
          });
        } else {
          this.registerPatient();
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    cancelar() {
      this.$emit('cancelar');
    },
    enviar(patientId) {
      const paciente = { patientId, ...this.patientForm };
      this.$emit('enviar', paciente);
      setTimeout(() => {
        this.clearPatientForm();
      }, 1000);
    },
    clearPatientForm() {
      this.$refs.patientForm.resetFields();
    },
  },
};
</script>

<style>
  .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
  }
  .el-col {
    padding-left: 10px;
  }
</style>
