<template>
  <div style='padding:0px 0px 0px 5px;'>
    <div class='container-Agenda'>
      <div class="dateContainer">
        <h1 style="margin:0px">AGENDA DE CITAS (Trabajo social).</h1>
        <h4 style="margin-top:0px"> {{ $dateString(selectedDateStr) }}</h4>
      </div>
    </div>
    <div v-show="false">Citas: {{ cantCitas  }}</div>
    <div style="display: flex; justify-content: space-between">
      <div class="table-agenda">
        <div v-for='(hora, idx) in horarios' :key='idx'>
          <div class='horario'>
            <strong>{{ hora.hora }}</strong>
            <div class='info-cita'>
              <div
                v-if='hora.type == "normal"'
                style="display: flex;
                justify-content: space-between"
              >
                <div class="opciones-horario">
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Reagendar"
                      placement="top-start"
                    >
                      <el-button
                        style="border: none;"
                        icon="el-icon-date"
                        circle
                        @click="reagendarShow(hora.cita)"
                      />
                    </el-tooltip>
                  </div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Cancelar"
                      placement="bottom"
                    >
                      <el-button
                        style="border: none;"
                        icon="el-icon-delete"
                        circle
                        @click="cancelarShow(hora.cita)"
                      />
                    </el-tooltip>
                  </div>
                </div>
                <div
                  style="display: flex;
                  justify-content: space-between;
                  align-items:center;
                  width: 100%"
                >
                  <div>
                    <label @click="openPatientInfo(hora.cita)">
                      {{ hora.cita.nombrePaciente }}
                      {{ hora.cita.apellidoPaciente }} - {{ hora.cita.id }}
                    </label>
                    <br>
                    <label>{{ hora.cita.type }} </label><br>
                  </div>
                  <div>
                    <AppointmentsComments
                      agenda="social"
                      :comments="hora.cita.comments"
                      :citaId="hora.cita.idcita"
                      :permisoParaModificar="permisoParaModificar"
                      typeAppointment="updatecommentTS"
                    />
                  </div>
                </div>
              </div>
              <div v-else-if="hora.type === 'blocked'" class="block-hour">
                <strong>
                  {{ hora.cita.comments }}
                </strong>
                <el-button id="btn-bloquear" @click="desbloquearCita(idx)">
                  <label>Desbloquear</label>
                </el-button>
              </div>
              <div v-else class="agregar-bloquear">
                <el-button
                  id="btn-agregar"
                  @click="agregarCita(idx)"
                >
                  <label>Agregar citas</label>
                </el-button>
                <el-button
                  id="btn-bloquear"
                  @click="handlerBloquear(idx)"
                >
                  <label>Bloquear</label>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendario" style="margin-top: -90px">
        <div>
          <el-col class="calendarArea">
            <div class="calendar">
              <div class="title">
                <p style=" font-weight: bold;font-size: 18px;color: #6a41bdb4;" >
                  SELECCIONE LA FECHA DESEADA
                </p>
              </div>
              <datepicker
                v-model="selectedDate"
                :language="languages['es']"
                :inline="true"
                :highlighted="highlighted"
                @changedMonth="changedMonth"
              />
            </div>
          <div class="busqueda">
            <div class="selects-agendas">
              <!-- <label>Trabajador social</label> -->
              <!-- <el-select
                v-model="tipo"
                placeholder="Seleccionar"
                style="width: 270px;"
                @change="(value) => getFilterCitas(value)"
              >
                <el-option
                  v-for="tipo in tipoAgenda"
                  :key="tipo.value"
                  :label="tipo.label"
                  :value="tipo.value"
                />
              </el-select> -->
              <el-button
                  style=" background-color: #9477d0;
                  margin-top: 20px;
                    color: #fff;
                    border: none;
                    width: 270px;
                    border-radius: 8px
                  "
                  @click="imprimirPDF()"
                  >
                  <strong>Imprimir agenda actual</strong>
                </el-button>
            </div>
          </div>
        </el-col>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="crearCitaModal"
      style="border-radius: 12px;"
      :close-on-click-modal="false"
    >
      <div style="display: flex;flex-direction: column;" class="crearCita">
        <span style="font-size: 18px;font-weight: bold;">Crear cita</span>
        <el-divider />
        <div style="display: flex; flex-direction: column; text-align:end;">
          <strong>{{ $dateString(crearCita.dia) }} A LAS {{ crearCita.hora }} HRS.</strong>
        </div>
        <el-form
          :model="crearCita"
          :rules="rules"
          ref="formSocial"
          status-icon
        >
          <el-form-item prop="nombrePaciente">
            <strong>Buscar paciente</strong>
            <el-autocomplete
              type="search"
              style="width: 100%"
              v-model="crearCita.nombrePaciente"
              :fetch-suggestions="querySearchAsync"
              placeholder="Buscar por nombre o número de expediente"
              @select="handleSelect"
            />
          </el-form-item>
          <el-form-item prop="notas">
            <strong>Notas</strong><br>
            <el-input type="textarea" v-model="crearCita.notas" />
          </el-form-item>
          <el-form-item style="display: flex; justify-content: end;">
            <el-button type="danger" @click="cerrarCita()">Cerrar</el-button>
            <el-button
              style="background-color: #9477d0; border: none; color: #fff;"
              @click="submitForm()"
            >Crear cita</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="Motivo"
      :visible.sync="bloquearHoraModal"
      style="border-radius: 12px"
      :close-on-click-modal="false"
    >
      <div>
        <el-form>
          <el-form-item>
            <strong>Motivo de bloqueo</strong> <br>
            <el-input type="textarea" v-model="motivoBloqueo" />
          </el-form-item>
          <el-button @click="bloquearHoraModal = false">Cerrar</el-button>
          <el-button
            type="primary"
            style="background-color: rgba(122, 7, 158, 0.897); border: none;"
            @click="bloquearHorario()"
          >Bloquear</el-button>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showCancelReason"
      style="border-radius: 12;"
      :close-on-click-modal="false"
    >
      <div style="padding: 30px;">
        <span style="font-size: 18px;font-weight: bold;">Cancelar cita</span>
        <el-divider />
        <el-form>
          <el-form-item>
            <strong>Motivo de Cancelación</strong> <br>
            <el-input type="textarea" v-model="reason" />
          </el-form-item>
          <div style="display:flex;justify-content: end;">
            <el-button @click="showCancelReason = false">Cerrar</el-button>
            <el-button
              :disabled="!reason"
              type="danger"
              style="border: none;"
              @click="cancel()"
            >Cancelar
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      id="main-popup"
      :visible.sync="dialogPatientVisible"
      width="80%"
      :close-on-press-escape="true"
      :before-close="handleClose"
    >
    <DialogPatientList :rowsValue="currentRow" />
  </el-dialog>
  <el-dialog
    :visible.sync="dialogImprimir"
    width="80%"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
  <div style="padding: 45px 0px 0 0px;">
    <iframe
      :src="pdfUrl" type="application/pdf" width="100%"
      height="600px"
    />
  </div>
  </el-dialog>
  <el-dialog
    :visible.sync="dialogCalendarSocial"
    :destroy-on-close="true"
    width="50%"
    >
    <div style="display: flex;flex-direction: column;" class="crearCita">
    <span style="font-size: 18px;font-weight: bold;">Reagendar cita</span>
    <label style="color: rgb(116, 116, 116); margin: 10px 0px">
      {{ patientSelected.nombrePaciente || '' }}
      {{ patientSelected.apellidoPaciente || '' }} -
      {{ patientSelected.expediente || '' }}
    </label>
    <Calendar
      v-if="dialogCalendarSocial"
      v-on:selected-hour="selectedHour"
      v-on:selected-date="selectedFecha"
      :selectedDateDefault="selectedDateStr"
      :disableds="reservedHours"
    />
    <div style="padding: 10px; display: flex; justify-content: flex-end;">
      <el-button
        type="danger"
        style="border-radius: 8px;"
        @click="handlerReagendar(false)"
      >
        Cancelar
      </el-button>
      <el-button
        id="button-fundacionLuz"
        :disabled="!date || !hour"
        @click=" handlerReagendar(true)">
        Aceptar
      </el-button>
    </div>
    </div>
  </el-dialog>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import DialogPatientList from '../../../components/dialogPatientListNew';
import AppointmentsComments from './commentAppointments';
import PdftPrint from '../../../API/pdfPrint';
import Calendar from '../../../components/Calendar';
import socketStore from '../../../store/socket';

const moment = require('moment');

export default {
  name: 'App',
  data() {
    return {
      cantCitas: 0,
      rules: {
        nombrePaciente: { required: true, message: 'Campo obligatorio', trigger: 'blur' },
        notas: [{ required: true, message: 'Este campo es requerido', trigger: 'blur' }],
      },
      dialogCalendarSocial: false,
      dialogImprimir: false,
      pdfUrl: '',
      dialogPatientVisible: false,
      currentRow: null,
      color: 'red',
      pacientes: [],
      reservedHours: [],
      timeout: null,
      bloquearHoraModal: false,
      socialAppointmentSw: false,
      showCancelReason: false,
      citaSelected: null,
      tipo: null,
      selectedDateStr: null,
      selectedDate: null,
      horarios: [],
      citas: [],
      blocked: [],
      languages: lang,
      highlighted: {
        daysOfMonth: [],
      },
      crearCitaModal: false,
      crearCita: {
        hora: null,
        dia: null,
        notas: null,
        nombrePaciente: '',
        pacienteId: null,
      },
      patientSelected: {
        id: null,
        idcita: null,
        time: null,
        date: null,
        comments: null, // comentarios
        nombrePaciente: null,
        apellidoPaciente: null,
        expediente: null,
        firstAppointment: false,
      },
      bloqueoHorario: {},
      motivoBloqueo: null,
      reason: null,
      permisoParaModificar: false,
      date: null,
      hour: null,
    };
  },
  components: {
    Datepicker,
    AppointmentsComments,
    DialogPatientList,
    Calendar,
  },
  methods: {
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.formSocial.validate((valid) => {
        if (valid) {
          this.crearCitaDB();
        } else {
          this.$message({
            message: 'Favor de llenar los campos requeridos.',
            type: 'error',
          });
          return false;
        }
      });
    },
    // async socketsOn() {
    //   socket.on('SOCKET_INCREMENT', async (mensaj) => {
    //     this.$store.dispatch('addAppointments', mensaj);
    //     await this.getFilterCitas();
    //   });
    // },
    async reagendarShow(row) {
      this.patientSelected = {
        id: row.id,
        idcita: row.idcita,
        time: row.time,
        date: row.date,
        comments: row.notas, // comentarios
        nombrePaciente: row.nombrePaciente,
        apellidoPaciente: row.apellidoPaciente,
        expediente: row.id,
        firstAppointment: false,
      };
      this.hour = row.time;
      this.date = row.date;
      await this.getReservedHours();
      this.dialogCalendarSocial = true;
    },
    async getReservedHours() {
      try {
        if (this.date) {
          const response = await this.axios(`patient/appointment/social/reserved-schedules/?date=${this.date}`);
          const { data } = response;
          this.blocked.forEach((element) => data.push({ time: element.time }));
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHours = data.map((x) => ({
              ...x,
              time: x.time.substring(0, 5),
            }));
          } else {
            this.reservedHours = [];
          }
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async handlerReagendar(seguir) {
      try {
        if (seguir) {
          const request = await this.axios.patch(
            `patient/appointment/social/reschedule/${this.patientSelected.idcita
            }`, {
              date: this.date,
              time: this.hour,
            },
          );
          if (request.status === 200) {
            const appointment = {
              id: this.patientSelected.id,
              idcita: this.patientSelected.idcita,
              date: this.date,
              time: this.hour,
              type: 'CITA - TRABAJO SOCIAL',
              nombrePaciente: this.patientSelected.nombrePaciente,
              apellidoPaciente: this.patientSelected.apellidoPaciente,
              comments: this.patientSelected.notas,
              location: null,
            };
            this.enviarCita(appointment, 'update');
            this.cerrarCita();
            this.dialogCalendarSocial = false;
          }
        } else {
          this.dialogCalendarSocial = false;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    selectedHour(value) {
      this.hour = value;
    },
    async selectedFecha(value) {
      this.date = value;
    },
    async imprimirPDF() {
      try {
        this.$loading();
        const citas = {
          citas: [...this.citas],
          tipoAgenda: 'trabajo social',
        };
        const pdf = await PdftPrint.getPrintRecepcion(citas, this.selectedDateStr);
        this.dialogImprimir = true;
        this.pdfUrl = pdf;
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
      }
    },
    obtenerPermisos() {
      const permisos = JSON.parse(localStorage.getItem('vuex'));
      if (permisos.auth.user.roleId === 'MD' || permisos.auth.user.roleId === 'OPTO') {
        this.permisoParaModificar = false;
      } else {
        this.permisoParaModificar = true;
      }
    },
    async querySearchAsync(queryString, cb) {
      if (queryString === '') {
        cb([]);
        return;
      }
      await this.buscarPaciente(queryString);
      const { pacientes } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(pacientes);
      }, 500);
    },
    handleSelect(item) {
      // eslint-disable-next-line prefer-destructuring
      this.patientSelected = {
        nombrePaciente: item.value.split(' - ')[0],
        id: item.link,
      };
      this.crearCita.nombrePaciente = item.value.toUpperCase();
      this.crearCita.pacienteId = item.link;
    },
    async getFilterCitas(runHighlights = true) {
      const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

      const request = await this.axios(`/patient/subsecuente/social?&date=${date}`);
      await this.getBlocked();
      if (request.data) {
        this.citas = request.data;
        this.$store.dispatch('addAppointments', this.citas);
        this.crearHorarios(5, 20, 10);

        if (runHighlights) {
          await this.highlights(date);
        }
      }
    },
    async getBlocked() {
      try {
        this.blocked = [];
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

        const request = await this.axios(`/patient/subsecuente/blockSchedule?type=CITA TRABAJO SOCIAL&date=${date}&subject=social&specialty=null`);
        if (request.status === 200) this.blocked = request.data;
      } catch (error) {
        // console.error(error);
      }
    },
    crearHorarios(inicio, final, saltos) {
      let hour = inicio;
      const min = 60 - saltos;
      const hourFinal = final - 1;
      const horas = [];
      for (let minutos = 0; minutos <= min; minutos += saltos) {
        if (minutos === 0 || minutos === saltos) {
          if (hour <= 9) {
            horas.push({ hora: `0${hour}:00` });
          } else {
            horas.push({ hora: `${hour}:00` });
          }
        }
        if (minutos !== 0) {
          if (hour <= 9) {
            horas.push({ hora: `0${hour}:${minutos}` });
          } else {
            horas.push({ hora: `${hour}:${minutos}` });
          }
        }
        if (minutos === min && hour <= hourFinal - 1) {
          minutos = 0;
          hour++;
        }
      }
      if (hour === hourFinal) {
        horas.push({
          hora: `${hour + 1}:00`,
        });
      }
      horas.shift();
      this.horarios = horas;
      this.citaEnhorario('create');
    },
    citaEnhorario(action = null, cita = null) {
      if (action === 'delete') {
        const idx = this.horarios.findIndex((element) => element?.cita?.idcita === cita.id);

        this.horarios[idx] = {
          hora: this.horarios[idx].hora,
          cita: null,
          type: null,
        };
      } else if (action === 'update') {
        const hourIdx = this.horarios.findIndex((element) => element?.hora === cita?.time);
        const idx = this.horarios.findIndex((element) => element?.cita?.idcita === cita.idcita);

        this.horarios[idx] = {
          hora: this.horarios[idx]?.hora,
          cita: null,
          type: null,
        };

        this.horarios[hourIdx] = {
          hora: this.horarios[hourIdx]?.hora,
          cita,
          type: 'normal',
        };
      } else if (action === 'blockd') {
        const idx = this.horarios.findIndex((element) => element?.hora === cita?.time);
        this.horarios[idx] = {
          hora: this.horarios[idx].hora,
          cita,
          type: 'blocked',
        };
      } else if (action === 'unblockd') {
        const idx = this.horarios.findIndex((element) => element?.cita?.id === cita.id);
        this.horarios[idx] = {
          hora: this.horarios[idx]?.hora,
          cita: null,
          type: null,
        };
      } else {
        const app = this.$store.getters.appointments;
        app.forEach((element) => {
          for (let i = 0; i < this.horarios.length; i++) {
            if (this.horarios[i]?.hora === element.time) {
              this.horarios[i] = {
                hora: this.horarios[i].hora,
                cita: element,
                type: 'normal',
              };
            }
          }
        });
        this.blocked.forEach((element2) => {
          for (let i = 0; i < this.horarios.length; i++) {
            if (this.horarios[i]?.hora === element2.time) {
              this.horarios[i] = {
                hora: this.horarios[i].hora,
                cita: element2,
                type: 'blocked',
              };
            }
          }
        });
      }

      if (this.horarios.length > 0) {
        this.cantCitas += 1;
      }
    },
    socketsOn() {
      socketStore.dispatch('socketEmit', {
        event: 'join_room',
        room: 'social',
      });

      socketStore.dispatch('socketOn', {
        event: 'appointments',
        room: 'social',
        callback: (data) => {
          this.addAppointmentSocket({
            appointment: data.appointment,
            action: data.action,
            message: `${data.user} ${data.message}`,
          });
        },
      });

      socketStore.dispatch('socketOn', {
        event: 'blockSchedule',
        room: 'social',
        callback: (data) => {
          this.addAppointmentSocket({
            appointment: data.appointment,
            action: data.action,
            message: `${data.user} ${data.message}`,
          });
        },
      });
    },
    async changedMonth(args) {
      const FORMAT = 'YYYY-MM-DD';
      let date = null;
      if (args.timestamp) {
        date = moment(new Date(args.timestamp)).format(FORMAT);
      } else {
        date = moment(args).format(FORMAT);
      }
      await this.highlights(date);
    },
    async highlights(date) {
      try {
        this.highlighted.daysOfMonth = [];

        const param = moment(date, 'YYYY-MM-DD').format('YYYY/MM');
        const year = param.split('/')[0];
        const month = param.split('/')[1];

        const response = await this.axios(`patient/social/highlights/${this.userId}`, {
          params: {
            year,
            month,
          },
        });
        if (response.data) {
          this.highlighted.daysOfMonth = response.data;
        }
      } catch (error) {
        // some code
      }
    },
    async buscarPaciente(fullName) {
      this.pacientes = [];
      const request = await this.axios(`/patient/list/v2?fullname=${fullName}&page=0`);
      request.data.map((res) => {
        this.pacientes.push({
          value: `${res.fullName} - ${res.patientId}`,
          link: res.patientId,
        });
        return null;
      });
    },
    agregarCita(idx) {
      this.crearCita = {};
      this.crearCitaModal = true;
      this.crearCita = {
        hora: this.horarios[idx].hora,
        dia: this.selectedDateStr,
      };
    },
    handlerBloquear(idx) {
      this.bloquearHoraModal = true;
      this.bloqueoHorario = {
        horario: this.horarios[idx].hora,
      };
    },
    async bloquearHorario() {
      try {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const hour = this.bloqueoHorario.horario;
        const request = await this.axios({
          method: 'post',
          url: '/patient/subsecuente/blockSchedule',
          data: {
            hour,
            date,
            type: 'CITA TRABAJO SOCIAL',
            procedure: null,
            specialty: null,
            subject: 'social',
            comments: this.motivoBloqueo,
          },
        });

        this.bloquearHoraModal = false;
        if (request) {
          this.enviarCita({
            date,
            time: hour,
            comments: this.motivoBloqueo,
            id: request?.data?.id,
          }, 'blockd');
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async desbloquearCita(idx) {
      try {
        const citaId = this.horarios[idx];
        const request = await this.axios.put(`patient/subsecuente/blockSchedule?citaId=${citaId.cita.id}`);
        if (request) {
          this.enviarCita({
            id: this.horarios[idx].cita.id,
            date: this.horarios[idx].cita.date,
          }, 'unblockd');
        }
      } catch (error) {
        // console.log(error);
      }
    },
    cerrarCita() {
      this.crearCita = {};
      this.crearCitaModal = false;
      this.$refs?.formSocial?.resetFields();
    },
    async crearCitaDB() {
      try {
        const cita = {
          ...this.crearCita,
          type: 'CITA - TRABAJO SOCIAL',
        };
        const request = await this.axios.post(`patient/appointment/social/${cita.pacienteId}`, {
          type: cita.type,
          location: null,
          userId: 42,
          date: cita.dia, // fecha
          time: cita.hora, // hora
          comments: cita.notas, // comentarios
        });
        if (request.status === 201) {
          const appointment = {
            ...request.data.response,
            location: null,
            date: cita.dia, // fecha
            time: cita.hora, // hora
            comments: cita.notas, // comentarios
            type: 'CITA - TRABAJO SOCIAL',
            nombrePaciente: this.patientSelected.nombrePaciente,
            apellidoPaciente: cita.apellidoPaciente,
            id: cita.pacienteId,
            idcita: request.data.response.id,
          };
          this.enviarCita(appointment, 'create');
          this.cerrarCita();
        }
      } catch (error) {
        // console.log(error);
      }
    },
    enviarCita(appointment, action) {
      const userStorage = JSON.parse(localStorage.getItem('vuex'));
      const userName = `${userStorage.auth.user.firstName} ${userStorage.auth.user.lastName}`;

      const actionsTypes = {
        create: 'ha creado una nueva cita.',
        update: 'ha actualizado una cita.',
        blockd: 'ha bloqueado un horario.',
        unblockd: 'ha desbloqueado un horario.',
        delete: 'ha cancelado una cita.',
      };

      if (action === 'blockd' || action === 'unblockd') {
        socketStore.dispatch('socketEmit', {
          event: 'blockSchedule',
          room: 'social',
          data: {
            message: actionsTypes[action] || 'ha creado una nueva cita.',
            action,
            user: userName,
            appointment,
          },
        });
      } else {
        socketStore.dispatch('socketEmit', {
          event: 'appointments',
          room: 'social',
          data: {
            message: actionsTypes[action] || 'ha creado una nueva cita.',
            action,
            user: userName,
            appointment,
          },
        });
      }
    },
    addAppointmentSocket(data) {
      const { appointment, action, message } = data;

      const date = this.selectedDateStr;
      const codition = (appointment.date === date) || ((action === 'blockd' || action === 'unblockd') && appointment.date === date);

      if (codition) {
        if (action === 'create') {
          this.$store.dispatch('setStateAppointment', appointment);
        } else if (action === 'delete') {
          this.$store.dispatch('delAppointment', appointment.id);
        } else if (action === 'update') {
          this.$store.dispatch('updateAppointment', appointment);
        }

        this.citaEnhorario(action || 'create', appointment);

        this.$notify({
          title: 'Notificación',
          message,
          type: action === 'create' ? 'success' : 'info',
        });
      }
    },
    cancelarShow(citaId) {
      this.showCancelReason = true;
      this.citaSelected = citaId.idcita;
    },
    async cancel() {
      try {
        const request = await this.axios.patch(`patient/subsecuente/social/cancel/${this.citaSelected}`, {
          cancelReason: this.reason,
        });
        if (request) {
          this.$store.dispatch('delAppointment', this.citaSelected);
          const appointment = {
            id: this.citaSelected,
            date: this.selectedDateStr,
            cancelReason: this.reason || null,
          };
          this.enviarCita(appointment, 'delete');
          this.$notify({
            type: 'success',
            message: 'Cancelación realizada con éxito',
          });
        }
        this.reason = null;
        this.showCancelReason = false;
      } catch (err) {
        this.$errorNotify(err);
      } finally {
        this.showCancelReason = false;
        this.reason = null;
      }
    },
    selectAgeRange(birthdate) {
      const bd = moment(birthdate);
      const today = moment().now;
      const diff = Math.abs(bd.diff(today, 'years'));
      if (diff < 1) {
        return 'Menor de 1 Año';
      }
      if (diff < 6) {
        return '1 a 5 Años';
      }
      if (diff < 12) {
        return '6 a 11 Años';
      }
      if (diff < 18) {
        return '12 a 17 Años';
      }
      if (diff < 30) {
        return '18 a 29 Años';
      }
      if (diff < 60) {
        return '30 a 60 Años';
      }
      return '60 Años o mas';
    },
    async getPatientData(patientId) {
      try {
        let patient = {};
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        patient = data ? {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : this.patient;
        const response2 = await this.axios.get(`patient/profile/${patientId}`);
        const data2 = response2.data;
        patient = data2 ? {
          ...data,
          ...data2,
          fullName: `${data.firstName} ${data.lastName}`,
          fullAddress: `${data2.address}, \
            ${data2.city}, ${data2.state}, ${data2.country}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : patient;
        return patient;
      } catch (error) {
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        return {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        };
      }
    },
    async openPatientInfo(item) {
      // id = patientId
      const { id } = item;
      const patient = await this.getPatientData(id);
      this.currentRow = {
        ...patient,
        id,
      };
      this.dialogPatientVisible = true;
    },
    closeDialog(clearHistory) {
      this.dialogPatientVisible = false;
      if (clearHistory) {
        this.$store.dispatch('app/setLastRow', {});
      }
    },
    handleClose(done) {
      this.dialogPatientVisible = false;
      this.$store.dispatch('app/setLastRow', {});
      done();
    },
  },
  async mounted() {
    this.pacientes = [];
  },
  async created() {
    this.$loading();
    this.selectedDate = moment().format('YYYY-MM-DD');
    this.obtenerPermisos();
    // await this.getBlocked();
    // await this.highlights(this.selectedDate);
    // await this.getFilterCitas(false);

    this.socketsOn();
    this.$loading().close();
  },
  beforeDestroy() {
    socketStore.dispatch('socketEmit', {
      event: 'leave room',
      room: 'social',
      data: {
        message: 'Ha salido de la sala de social',
      },
    });
  },
  watch: {
    async selectedDate(val) {
      if (val) {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.selectedDateStr = date;
        await this.getFilterCitas(false);
      }
    },
    async date() {
      await this.getReservedHours();
    },
  },
};
</script>

  <style lang="scss">
  .container-Agenda{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin-right: 10px;
    margin-bottom: 18px;
    color:#6a41bdb4;
    .containerInfo {
      display: flex;
      flex-direction: column;
      .titleContainer {
        height: 30px;
      }
      .dateContainer {
        height: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 400px;
      }
    }
  }
  .table-agenda {
    overflow-x: hidden;
    height: 700px;
    width: 80%;
    min-width: 850px;
  }
  .horario {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 30px;
    strong {
      color:#6a41bdb4;
    }
  }
  .info-cita {
    gap: 10px;
    border-top: solid 1px gray;
    padding: 8px 5px 0px 8px;
    min-height: 30px;
    width: 100%;
    font-size: 1rem;
  }
  .agregar-bloquear {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    text-align: center;
    color: rgb(179, 179, 179);
    #btn-agregar , #btn-bloquear{
      border-radius: 12px;
      font-size: 13px;
      color: rgba(122, 7, 158, 0.897);
      border: none;
      font-weight: 900;
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(157, 108, 229);
    border-radius: 12px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(123, 59, 218, 0.855);
    border-radius: 12px;
  }
  .vdp-datepicker__calendar {
    padding: 5px;
  }
  .vdp-datepicker__calendar .cell.selected {
    background: #6a41bdd7 !important;
    color: #fff;
  }
  .vdp-datepicker__calendar .cell.highlighted {
      background: #c4a7ff !important;
      color: #fff;
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled):hover {
    background: rgba(104, 85, 247, 0.25) !important;
    color: #fff;
    border: none;
  }
  .vdp-datepicker__calendar {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
    box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: none !important;
  }
  .vdp-datepicker__calendar .cell.blank:hover {
    background: #ffffff !important;
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
    border-radius: 50%;
  }
  .vdp-datepicker__calendar .cell.selected:hover {
    background: #006eff;
  }
  .vdp-datepicker__calendar .cell:hover {
    background: #006eff;
  }
  </style>
  <style lang="scss" scoped>
    .crearCita {
    font-size: 18px;
    padding: 40px;
  }
    .busqueda {
      width: 100%;
      text-align: center;
      padding: 15px;
    }
    .title {
      color: #6a41bdca;
    }
    .selects-agendas {
      margin-top: 20px;
      display:flex;
      flex-direction: column;
      align-items: left;
      text-align: left;
    }
    label {
      color: #6a41bdca;
      font-weight: bold;
    }
    .block-hour {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      strong {
        color: rgb(55, 55, 55);
      }
      button {
        border: none;
      }
    }
    .opciones-horario {
      display: flex;
      flex-direction: column;
      width: 53px;
    }
  </style>
