import httpAxios from './index';

export const getPatient = async (id) => {
  try {
    const { data } = await httpAxios.get(`/patient/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const validateOldInformation = async (patientId) => {
  try {
    const { data } = await httpAxios.get(`/patient/oldInfo/${patientId}`);
    return data;
  } catch (error) {
    return error;
  }
};
