<template>
  <div class="container-information-patient">
    <div class="left-content">
      <el-avatar
        icon="el-icon-user-solid"
        size="large"
        shape="circle"
      />
      <div style="display:flex; align-items:center; padding: 10px;">
        <span style="font-weight: bold;">
          {{ newName }} {{ newLastName }}
        </span>
        <el-button
          icon="el-icon-edit"
          style="margin-left: 10px;"
          circle
          @click="updateName = !updateName"
        />
      </div>
      <div v-if="updateName" style="width: 90%;">
        <div class="form-changeName">
          <label>Nombre</label>
          <el-input v-model="newName" type="text" />
          <label>Apellido</label>
          <el-input v-model="newLastName" type="text" />
          <label>Sexo</label>
          <el-select v-model="newSex" placeholder="Sexo">
            <el-option
              v-for="item in sexos"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button
            id="btn-update-name"
            @click="updateFullname(newName, newLastName, newSex)">
            Actualizar
          </el-button>
        </div>
      </div>
      <div class="content-information-contact">
        <div>
          <div class="information-contact">
            <i class="el-icon-message" />
            <div>
              <label>Correo electrónico</label><br>
              <label> {{ patientInfo.email === null ? 'Sin registrar' : patientInfo.email }}</label>
            </div>
          </div>
          <div class="information-contact">
            <i class="el-icon-phone-outline" />
            <div>
              <label>Número fijo</label><br>
              <label>
                {{ patientInfo.phone === null ? 'Sin registrar' : patientInfo.phone }}
              </label>
            </div>
          </div>
        </div>
        <div>
          <div class="information-contact">
            <i class="el-icon-phone-outline" />
            <div>
              <label>Número celular</label><br>
              <label>
                {{ patientInfo.cellPhone === null ? 'Sin registrar' : patientInfo.cellPhone }}
              </label>
            </div>
          </div>
          <div class="information-contact">
            <i class="el-icon-location-outline" />
            <div>
              <label>Domicilio</label><br>
              <label>
                {{patientInfo.address === null ? 'Sin registrar' : patientInfo.address }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-fotter" v-if="!updateName">
        <el-button
          size="mini"
          icon="el-icon-edit"
          style="background: #8F70C7; color: #fff; border-radius: 7px;"
          @click="gotTopatientInfo()"
          >Editar datos del paciente
        </el-button>
      </div>
      <div class="fotter-expediente">
        <label>EXPEDIENTE: {{ patientInfo.patientId || patientInfo.id }}</label>
      </div>
    </div>
    <div class="right-content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="OFTALMOLOGÍA" name="cero">
          <div v-if="rowsValue.id" class="tab-scroll">
            <history :patientId="rowsValue.id" type="OFTA" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="OPTOMÉTRIA" name="uno">
          <div v-if="rowsValue.id" class="tab-scroll">
            <history :patientId="rowsValue.id" type="OPTO" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="SOCIOECONÓMIA" name="dos">
          <div v-if="rowsValue.id" class="tab-scroll">
            <historySocial :patientId="rowsValue.id"/>
          </div>
        </el-tab-pane>
        <el-tab-pane label="ANTECEDENTES" name="tres" v-if="antecedente">
          <div class="tab-scroll">
            <div class="information-Patient" v-if="patientInfo">
              <div class="antecedentes-paciente">
                <label for="USO DE LENTES">
                  <strong>USO DE LENTES</strong><br>
                  <span>{{ patientInfo.glasses }}</span><br>
                </label>
                <label for="DIABETES">
                  <strong>DIABETES</strong><br>
                  <span>{{ patientInfo.diabetes }}</span><br>
                </label>
                <label for="GLAUCOMA">
                  <strong>GLAUCOMA</strong><br>
                  <span>{{ patientInfo.glaucoma }}</span><br>
                </label>
                <label for="ALCOHOLISMO">
                  <strong>ALCOHOLISMO</strong><br>
                  <span>{{ patientInfo.alcoholism }}</span><br>
                </label>
                <label for="HEPATITIS">
                  <strong>HEPATITIS</strong><br>
                  <span>{{ patientInfo.hepatitis }}</span><br>
                </label>
                <label for="TABAQUISMO">
                  <strong>TABAQUISMO</strong><br>
                  <span>{{ patientInfo.smoking }}</span><br>
                </label>
                <label for="CANCER">
                  <strong>CANCER</strong><br>
                  <span>{{ patientInfo.cancer }}</span><br>
                </label>
                <label for="MIOPIA">
                  <strong>MIOPIA</strong><br>
                  <span>{{ patientInfo.myopia }}</span><br>
                </label>
                <label for="CATARATAS C.">
                  <strong>CATARATAS C.</strong><br>
                  <span>{{ patientInfo.congenitalCataracts }}</span><br>
                </label>
                <label for="HIPERTENSIÓN A.">
                  <strong>HIPERTENSIÓN A.</strong><br>
                  <span>{{ patientInfo.arterialHypertension }}</span><br>
                </label>
                <label for="ALERGIAS">
                  <strong>ALERGIAS</strong><br>
                  <span>{{ patientInfo.allergy }}</span><br>
                </label>
                <label for="VIH">
                  <strong>VIH</strong><br>
                  <span>{{ patientInfo.vih }}</span>
                </label>
              </div>
            </div>
            <div v-else>
              <label>Sin registros previos</label>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="DOCUMENTOS" name="cuatro">
          <div v-if="rowsValue.id">
            <Docs
              :patientId="rowsValue.id"
              :visible="true"
              :key="300"
              :clossable="false"
              :destroy-on-close="true"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="INFORMACIÓN GENERAL" name="cinco">
          <div class="tab-scroll">
            <div v-if="patientInfo.birthplace">
              <div class="information-Patient">
                <strong>ESTADO CIVIL</strong><br>
                <label>{{ patientInfo.maritalStatus }}</label>
              </div>
              <div class="information-Patient">
                <strong>LUGAR DE NACIMIENTO</strong><br>
                <label>{{ patientInfo.birthplace }}</label>
              </div>
              <div class="information-Patient">
                <strong>LUGAR DONDE RESIDE</strong><br>
                <label>{{ patientInfo.city }}, {{ patientInfo.state }}</label>
              </div>
              <div class="information-Patient">
                <strong>PARTICIPA COMO</strong><br>
                <label>{{ patientInfo.participateAs }}</label>
              </div>
              <div class="information-Patient">
                <strong>DERECHOHABIENCIA</strong><br>
                <label>
                  {{ patientInfo.affiliation === null? 'Sin registrar' : patientInfo.affiliation}}
                </label>
              </div>
              <div class="information-Patient">
                <strong>ETNIA</strong><br>
                <label>
                  {{ patientInfo.ethnic === null? 'Sin registrar' : patientInfo.ethnic}}
                </label>
              </div>
              <div class="information-Patient">
                <strong>OCUPACIÓN</strong><br>
                <label>
                  {{ patientInfo.occupation === null? 'Sin registrar' : patientInfo.occupation}}
                </label>
              </div>
              <div class="information-Patient">
                <strong>REFERENCIA</strong><br>
                <label>{{ patientInfo.reference }}</label>
              </div>
            </div>
            <div v-else>
              <label>Sin registros previos</label>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="NOTAS DE ARCHIVO">
          <div class="row">
            <div class="right-container" style="height: inherit;">
              <div style="height: 440px">
                <Comments :patientId="rowsValue.id" />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import history from '../views/patient/HistoryAppointment';
import historySocial from '../views/patient/SocialAppointment';
import Docs from '../views/Docs/index';
import Comments from '../views/patient/Comments';

export default {
  props: {
    rowsValue: {
      type: Object,
    },
  },
  data() {
    return {
      userRole: null,
      updateName: false,
      newName: null,
      newLastName: null,
      newSex: null,
      sexos: [
        {
          value: 'Masculino',
          label: 'Masculino',
        },
        {
          value: 'Femenino',
          label: 'Femenino',
        },
      ],
      antecedente: {
        glasses: null,
        diabetes: null,
        glaucoma: null,
        alcoholism: null,
        hepatitis: null,
        smoking: null,
        cancer: null,
        myopia: null,
        congenitalCataracts: null,
        arterialHypertension: null,
        allergy: null,
        vih: null,
      },
      activeName: 'uno',
      patientInfo: null,
      loading: true,
    };
  },
  components: {
    history,
    Docs,
    historySocial,
    Comments,
  },
  methods: {
    async getAllInformations() {
      this.patientInfo = this.rowsValue;
      this.newSex = this.rowsValue.sex;
      const objectKeys = Object.keys(this.antecedente);
      objectKeys.forEach((key) => {
        if (this.patientInfo[key] === null) {
          this.patientInfo[key] = 'Sin evaluar.';
        } else if (this.patientInfo[key] === false) {
          this.patientInfo[key] = 'No';
        } else if (this.patientInfo[key] === true) {
          this.patientInfo[key] = 'Si';
        }
      });
    },
    async updateFullname(nombre, apellido, sexo) {
      const patientId = this.rowsValue.id;
      const request = await this.axios.put(`/patient/updateName/${patientId}/${nombre}/${apellido}`, {
        sex: sexo,
      });
      if (request.status === 204) {
        this.$notify({
          type: 'success',
          title: '¡Éxito!',
          message: 'Información de paciente actualizada.',
        });
        this.updateName = false;
      }
      this.updateName = false;
    },
    goToOfta() {
      const patientId = this.rowsValue.id;
      const citaId = this.rowsValue.idcita;
      localStorage.setItem('prevOfta', this.rowsValue.especialidadId);
      this.$router.push(`/admin/evaluations/ofta/${patientId}/${citaId}`);
    },
    goToOpto() {
      const patientId = this.rowsValue.id;
      this.$router.push(`/admin/evaluations/opto/${patientId}`);
    },
    gotTopatientInfo() {
      const patientId = this.rowsValue.id;
      this.$router.push(`/admin/patient/general-profile/${patientId}`);
    },
  },
  async mounted() {
    const localAuth = localStorage.getItem('vuex');
    this.newName = this.rowsValue.firstName;
    this.newLastName = this.rowsValue.lastName;
    const user = JSON.parse(localAuth);
    this.userRole = user.auth.user.roleId;
    this.getAllInformations();
  },
  watch: {
    async rowsValue() {
      this.newName = this.rowsValue.firstName;
      this.newLastName = this.rowsValue.lastName;
      this.getAllInformations();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/dialogNew.scss';
.antecedentes-paciente {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  strong {
    font-weight: 600;
    color: #737373;
  }
}
</style>

<style scoped>
.left-content {
  max-width: 400px;
}
</style>
