<template>
    <div style="padding: 20px;">
      <h1 class="title-citas">CITAS CANCELADAS</h1>
      <div class="dateContainer">
        <h4 style="color: #9477d0;"> {{ $dateString(selectedDateStr) }}</h4>
      </div>
      <el-table
        :data="citasCanceladas"
      >
        <el-table-column prop="nombrePaciente" label="Nombre" width="180" />
        <el-table-column prop="apellidoPaciente" label="Apellido" width="200" />
        <el-table-column prop="type" label="Tipo de cita" width="180" />
        <el-table-column prop="time" label="Hora" width="70" />
        <el-table-column prop="cancelReason" label="Motivo"  />
      </el-table>
    </div>
 </template>

<script>
export default {
  name: 'citasCanceladasTable',
  props: {
    citasCanceladas: {
      type: Array,
      default: () => [],
    },
    selectedDateStr: {
      type: String,
      default: '',
    },
  },
  methods: {
    imprimirCitasCanceladas() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-citas {
    font-size: 2em;
    margin: 0;
    color: #6a41bdb4;
  }
  #fecha-title {
    font-size: 1em;
    margin: 0;
    color: #6a41bdb4;
    font-weight: bold;
  }
</style>
