<template>
<div style='padding:0px 0px 0px 5px;'>
  <div class='container-Agenda'>
    <div class="dateContainer">
      <h1 style="margin:0px">AGENDA DE CITAS</h1>
      <h4 style="margin-top:0px"> {{ $dateString(selectedDateStr) }}</h4>
      <h4 style="margin-top:0px" v-if="clase !== 'Gabinete'">{{
        clase === 'Médico' ? 'Consulta general' : `${clase} / ${especialidadNombre || ''}`
      }}
      </h4>
      <h4 v-else> {{ `${clase}/${procedimiento || ''}` }}</h4>
    </div>
  </div>
  <div v-show="false">Citas: {{ cantCitas  }}</div>
  <div style="display: flex; justify-content: space-between">
    <div class="table-agenda">
      <div v-for='(hora, idx) in horarios' :key='idx'>
        <div class='horario'>
          <label id="label-hora">{{ hora.hora }}</label>
          <div class='info-cita'>
            <div
              v-if='hora.type == "normal"'
              style="display: flex; justify-content: space-between">
              <div class="opciones-horario" v-if="permisoParaModificar">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Reagendar"
                  placement="top-start"
                  v-if="
                    clase === 'Médico' ||
                    clase === 'Gabiente' ||
                    (clase === 'Especialidad' && especialidad !== null)
                  "
                >
                  <el-button
                    style="border: none;"
                    icon="el-icon-date"
                    circle
                    @click="reagendarShow(hora.cita)"
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Cancelar"
                  placement="left"
                >
                  <el-button
                    style="border: none;"
                    icon="el-icon-delete"
                    circle
                    @click="cancelarShow(hora.cita)"
                  />
                </el-tooltip>
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  content="Confirmar"
                  placement="bottom"
                >
                  <el-button
                    style="border: none;"
                    icon="el-icon-circle-check"
                    circle
                    @click="confirmarShow(hora.cita)"
                  />
                </el-tooltip> -->
                <el-tooltip
                  v-if="hora.cita.subject !== 'Gabinete'"
                  class="item"
                  effect="dark"
                  content="Editar cita"
                  placement="bottom"
                >
                  <el-button
                    style="border: none;"
                    icon="el-icon-edit"
                    circle
                    id="btn-agregar"
                    @click="editarCita(idx)"
                    v-if="
                      clase !== null && (especialidad != null || procedimiento != null) ||
                      clase === 'Médico' ||
                      (clase === 'Gabinete' && procedimiento != null) ||
                      clase === 'EMERGENCIA'"
                  />
                </el-tooltip>
              </div>
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div>
                  <label @click="openPatientInfo(hora.cita)">
                    {{ hora.cita.nombrePaciente || ''}}
                    {{ hora.cita.apellidoPaciente || ''}} -
                    {{ hora.cita.id || ''}}
                  </label>
                  <br>
                  <label v-if="hora.cita.subject == 'Médico'">
                      {{
                        hora.cita.procedure !== null ?
                        ` Consulta general - ${hora.cita.procedure} - (${ hora.cita.type })` :
                        ` Consulta general - (${ hora.cita.type })`
                      }}
                  </label>
                  <label
                    v-if="hora.cita.subject === 'Gabinete'"
                    @click="showGabinete(hora.cita.idcita)"
                  >
                    {{
                      hora.cita.procedure !== null ?
                      `Estudio de Gabinete - ${hora.cita.procedure } - (${ hora.cita.type })` :
                      `Estudio de Gabinete - (${ hora.cita.type })`
                    }}
                  </label>
                  <label v-if="hora.cita.subject == 'Especialidad'">
                    {{
                      hora.cita.procedure !== null ?
                      `${hora.cita.especialidad || ''}
                        - ${hora.cita.procedure} - (${ hora.cita.type })`:
                      `${hora.cita.especialidad || ''} - (${ hora.cita.type })`
                    }}
                  </label>
                  <div style="width: 300px;margin-top:5px; display: flex;gap:10px;">
                    <div>
                      <el-select
                        v-model="hora.cita.statusId"
                        placeholder="Seleccionar"
                        @change="(e) => onStatusChange(e, hora.cita.idcita, idx)"
                        size="small"
                        style="min-width: 185px;"
                      >
                        <el-option
                        v-for="item in statusOptions"
                        :key="item.id"
                        :label="item.status"
                        :value="item.id"
                      />
                      </el-select>
                      <div :style="{ background: hora.cita.colorCode, height: 5 + 'px' }" />
                    </div>
                    <div>
                      <el-select
                        v-model="hora.cita.cost"
                        placeholder="Seleccionar"
                        @change="(e) => onCostChange(hora.cita.idcita, idx)"
                        size="small"
                      >
                        <el-option
                        v-for="itemCost in cost"
                        :key="itemCost.value"
                        :label="itemCost.label"
                        :value="itemCost.value"
                      />
                      </el-select>
                      <div
                        v-if="hora.cita.cost === 'sin costo'"
                        :style="{background: '#ff7675', 'height' : '5px', 'max-width' : '185px'}"
                      />
                      <div
                        v-if="hora.cita.cost === 'con costo'"
                        :style="{background: '#4cd137', 'height' : '5px', 'max-width' : '185px'}"
                      />
                      <div
                        v-if="hora.cita.cost === 'costo no definido'"
                        :style="{background: '#c4a7ff', 'height' : '5px', 'max-width' : '185px'}"
                      />
                      <div v-if="hora.cita.cost !== null" style="width: 230px;">
                        <label
                          style="margin:5px;display: block;color: #696969;font-size:12px;">
                          Por {{hora.cita.createdByCost}}
                          {{hora.cita.createdByCostLastName}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <AppointmentsComments
                    agenda="general"
                    :comments="hora.cita.comments"
                    :citaId="hora.cita.idcita"
                    :permisoParaModificar="permisoParaModificar"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="hora.type === 'blocked'" class="block-hour">
              <strong>{{ hora.cita.comments }}</strong>
              <el-button id="btn-bloquear" @click="confirmUnblock(idx)">
                <label>Desbloquear</label>
              </el-button>
            </div>
            <div v-else class="agregar-bloquear">
              <div v-if="permisoParaModificar">
                <el-button
                  id="btn-agregar"
                  @click="agregarCita(idx)"
                  v-if="
                    clase !== null && (especialidad != null || procedimiento != null) ||
                    clase === 'Médico' ||
                    (clase === 'Gabinete' && procedimiento != null) ||
                    clase === 'EMERGENCIA'"
                >
                  <label>Agregar cita</label>
                </el-button>
                <el-button
                  id="btn-bloquear"
                  v-if="(
                    especialidad != null || procedimiento != null) ||
                    clase === 'Médico' ||
                    (clase === 'Gabinete' && procedimiento != null)"
                    @click="handlerBloquear(idx)"
                  >
                  <label>Bloquear</label>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendario" style="margin-top: -90px">
      <div>
        <el-col class="calendarArea">
          <div class="calendar">
            <div class="title">
              <p
                style="
                  font-weight: bold;
                  font-size: 18px;
                  color: #6a41bdb4
                "
              >
             SELECCIONE LA FECHA DESEADA
              </p>
            </div>
            <datepicker
              v-model="selectedDate"
              :language="languages['es']"
              :inline="true"
              :highlighted="highlighted"
              @changedMonth="changedMonth"
            />
          </div>
          <div class="busqueda">
          <div class="selects-agendas">
            <label>Agenda</label>
            <el-select
              v-model="clase"
              placeholder="Seleccionar"
              style="width: 270px;"
            >
              <el-option
                v-for="tipo in claseAgenda"
                :key="tipo.value"
                :label="tipo.label"
                :value="tipo.value"
              />
            </el-select>
          </div>
          <div class="selects-agendas" v-if="clase == 'Especialidad'">
            <label>Especialidad</label>
            <el-select
              v-model="especialidad"
              placeholder="Seleccionar"
              style="width: 270px;"
              @change="getFilterCitas()"
            >
              <el-option
                v-for="tipo in especialidades"
                :key="tipo.value"
                :label="tipo.label"
                :value="tipo.value"
              />
            </el-select>
          </div>
          <div class="selects-agendas" v-if="clase === 'Gabinete'">
            <label>Tipo de agenda Gabinete</label>
            <el-select
              v-model="procedimiento"
              placeholder="Seleccionar"
              style="width: 270px;"
              @change="getFilterCitas()"
            >
              <el-option
                v-for="tipo in catalogoGabienteAgenda"
                :key="tipo.value"
                :label="tipo.label"
                :value="tipo.value"
              />
            </el-select>
          </div>
          <div class="selects-agendas">
            <el-button
              style="
                background-color: #9477d0;
                width: 270px;
                border-radius: 8px;
                margin: 10px;
                color: #fff;"
              @click="imprimirPDF()"
              >
              <strong>Imprimir agenda actual</strong>
            </el-button>
            <el-button
              v-if="tipo !== 'EMERGENCIA'"
              style="
                background-color: #9477d0;
                width: 270px;
                border-radius: 8px;
                margin-top: 10px;
                color: #fff;"
              @click="emergenciaHandler('create')"
              >
              <strong>Crear cita de emergencia</strong>
            </el-button>
            <el-button
              v-else
              style="
                background-color: #9477d0;
                width: 270px;
                border-radius: 8px;
                margin-top: 10px;
                color: #fff;"
              @click="emergenciaHandler('cancel')"
              >
              <strong>Cerrar cita de emergencia</strong>
            </el-button>
            <el-button
              id="button-fundacionLuz"
              @click="getFilterCitasCancel() "
              style="
                  background-color: #9477d0;
                  width: 270px;
                  border-radius: 8px;
                  margin-top: 10px;
                  color: #fff;">
              <strong>Citas canceladas</strong>
            </el-button>
            <!-- <PdfSocial
              id="PdfSocial"
              :citas="citas"
              :appointmentDate="selectedDateStr"
              ref="printComponent"
              v-show="true"
            /> -->
          </div>
        </div>
      </el-col>
    </div>
    </div>
  </div>
  <el-dialog
    :visible.sync="crearCitaModal"
    style="border-radius: 12px; padding: 12px;"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div style="padding: 30px;">
      <span style="font-size: 18px;font-weight: bold;">
        {{ action === 'create' ? 'CREAR CITA' : 'EDITAR CITA' }}
        {{ clase === 'Médico' ? 'CONSULTA GENERAL' : clase.toUpperCase() }}
      </span>
      <el-divider/>
      <div style="display:flex;flex-direction:column;text-align: right;" class="crearCita">
        <div style="display: flex; flex-direction: column;">
          <label style="font-weight: 600; font-size: 16px;margin-bottom: 15px;">
            <strong>
              {{ $dateString(crearCita.dia) }}
            </strong>A LAS
            <strong>
              {{ crearCita.hora }} HRS.
            </strong>
          </label>
        </div>
        <p v-if="needUpdate">{{ 'PACIENTE DESACTUALZIADO' }}</p>
        <div v-if="especialidadNombre">
          <strong style="font-size: 16px; color: #696969;">
            {{ especialidadNombre.toUpperCase() }}
          </strong>
        </div>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <div style="display: flex; gap: 10px;">
          <el-form-item  v-if="clase !== 'EMERGENCIA' && clase !== 'Gabinete'" prop="tipo">
            <strong>Tipo de cita</strong><br>
              <el-select
                v-model="ruleForm.tipo"
                placeholder="Seleccionar"
                style="width: 195px;"
                @change="changeCita()"
              >
                <el-option
                  v-for="tipo in tipoAgenda"
                  :key="tipo.value"
                  :label="tipo.label"
                  :value="tipo.value"
                />
              </el-select>
          </el-form-item>
          <el-form-item prop="nombre">
            <el-row>
              <el-col>
                <strong>Buscar paciente</strong><br>
                <el-autocomplete
                  :disabled="action === 'update'"
                  type="search"
                  v-model="ruleForm.nombre"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="Buscar por Nombre"
                  @select="handleSelect"
                  style="width: 450px;"
                >
                  <template slot-scope="{ item }">
                    <div class="value">{{ item.value }}</div>
                  </template>
                </el-autocomplete>
              </el-col>
            </el-row>
          </el-form-item>
        </div>
        <EstudiosGabineteVue
          v-if="clase === 'Gabinete' && showGabineteModal === true"
          v-on:appointments-gabinete="(values) => crearCitaGabinete(values)"
          :clear="triggerClear"
          :appointmentId="citaGabineteId"
        />
        <div style="display:flex;gap:10px;" v-if="clase !== 'Gabinete'">
          <el-form-item prop="procedimiento" >
            <strong>Procedimiento</strong><br>
            <el-select
              v-model="ruleForm.procedimiento"
              placeholder="Seleccionar Procedimiento"
              v-if="clase === 'Especialidad'"
            >
              <el-option
                v-for="item in options[especialidadNombre]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-select
              v-model="ruleForm.procedimiento"
              placeholder="Seleccionar Procedimiento"
              v-if="clase === 'Médico'"
            >
              <el-option
                v-for="item in options['general']"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-select
              v-model="ruleForm.procedimiento"
              placeholder="Seleccionar Procedimiento"
              v-if="clase === 'Gabinete'"
            >
              <el-option
                v-for="item in gabineteProcedures"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="tiposProcedimiento" v-if="ruleForm.procedimiento === 2">
            <strong>Tipo de procedimiento</strong><br>
            <el-select
              v-model="ruleForm.tipoProcedimiento"
              placeholder="Seleccionar"
            >
              <el-option
                v-for="item in options[especialidadNombre]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item>
          <strong>Notas</strong> <br>
          <el-input type="textarea" prop="notas" v-model="ruleForm.notas" />
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end;">
          <el-button @click="cerrarCita()">Cerrar</el-button>
          <el-button
            type="primary"
            style="background-color: rgba(122, 7, 158, 0.897); border: none;"
            @click="submitForm('ruleForm')"
          >{{ action === 'create' ? 'CREAR CITA' : 'EDITAR CITA' }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
  <el-dialog
    :visible.sync="bloquearHoraModal"
    style="padding: 10px;"
  >
    <el-form class="dialog-block">
      <el-form-item>
        <strong>Motivo de bloqueo</strong> <br>
        <el-input type="textarea" v-model="motivoBloqueo" />
      </el-form-item>
      <el-button @click="bloquearHoraModal = false">Cerrar</el-button>
      <el-button
        id="button-fundacionLuz"
        @click="bloquearHorario()"
      >Bloquear</el-button>
    </el-form>
  </el-dialog>
  <el-dialog
      title="Cancelar cita"
      :visible.sync="showCancelReason"
      style="border-radius: 12px"
      :close-on-click-modal="false"
    >
    <div style="padding: 10px;">
      <label>{{ patientSelected }}</label>
      <el-form>
        <el-form-item>
          <strong>Motivo de Cancelación</strong> <br>
          <el-input type="textarea" v-model="reason" />
        </el-form-item>
        <el-button @click="showCancelReason = false">Cerrar</el-button>
        <el-button
          type="danger"
          style="border: none;"
          @click="cancel()"
        >Cancelar</el-button>
      </el-form>
    </div>
  </el-dialog>
  <el-dialog
    :visible.sync="dialogConfirmVisible"
    :close-on-click-modal="false"
    width="30%"
    center
  >
    <span> Desea confirmar la cita?</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogConfirmVisible = false">Cancel</el-button>
      <el-button type="primary" @click="confirm()">Confirmar</el-button>
    </span>
  </el-dialog>
  <el-dialog
    :visible.sync="dialogConfirmBlockVisible"
    :close-on-click-modal="false"
    width="30%"
    center
  >
    <span>Seguro desea desbloquear el horario?</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogConfirmBlockVisible = false">Cancel</el-button>
      <el-button type="primary" @click="desbloquearCita">Confirmar</el-button>
    </span>
  </el-dialog>

  <el-dialog
    id="main-popup"
    :visible.sync="dialogPatientVisible"
    width="80%"
    :close-on-press-escape="true"
    :before-close="handleClose"
  >
    <dialogPatientList :rowsValue="currentRow" />
  </el-dialog>
  <el-dialog
    :visible.sync="dialogCalendarEspecialidad"
    :destroy-on-close="true"
    title="Re-agendar cita"
    width="50%"
    >
    <Calendar
      v-if="dialogCalendarEspecialidad"
      v-on:selected-hour="selectedHour"
      v-on:selected-date="selectedFecha"
      :selectedDateDefault="selectedDateStr"
      :disabledsEspeciality="reservedHours"
    />
    <div style="padding: 10px; display: flex; justify-content: flex-end;">
      <el-button
        type="danger"
        style="border-radius: 8px;"
        @click="handlerReagendar(false)"
      >
        Cancelar
      </el-button>
      <el-button
        id="button-fundacionLuz"
        :disabled="!date || !hour"
        @click=" handlerReagendar(true)">
        Aceptar
      </el-button>
    </div>
  </el-dialog>
  <el-dialog
    id="main-popup"
    width="60%"
    :visible.sync="dialogCancelVisible"
  >
    <vue-html2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="HISTORIAL"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <citasCanceladasTable
        slot="pdf-content"
        :citasCanceladas="citasCanceladas"
        :selectedDateStr="selectedDateStr"
      />
    </vue-html2pdf>
    <div style="padding: 20px;">
      <div class="opciones">
        <el-button
          v-if="citasCanceladas.length > 0"
          id="button-fundacionLuz"
          @click="imprimirCitasCanceladas() "
          style="border-radius: 12px; margin-top: 10px;">
          <strong>Imprimir citas</strong>
        </el-button>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    :visible.sync="dialogImprimir"
    width="80%"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
  <div style="padding: 45px 0px 0 0px;">
    <iframe
      :src="pdfUrl" type="application/pdf" width="100%"
      height="600px"
    />
  </div>
  </el-dialog>
  <el-dialog
    :visible.sync="showRegisterPatient"
    width="80%"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
  <div style="padding: 30px;">
    <CreatePatient @enviar="callbackRegisterPatient" @cancelar="callbackCancel"/>
  </div>
  </el-dialog>
  <el-dialog
    :visible.sync="showAppointmentGabinete"
    width="80%"
    :close-on-click-modal="false"
    :before-close="handleClose"
    :destroy-on-close="true"
    >
    <div style="padding: 10px;">
      <EstudiosGabineteVue
        v-if="clase === 'Gabinete' && showAppointmentGabinete"
        v-on:appointments-gabinete="(values) => crearCitaGabinete(values)"
        :clear="triggerClear"
        :appointmentId="citaGabineteId"
        :showAppointmentGabinete="showAppointmentGabinete"
      />
    </div>
  </el-dialog>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import VueHtml2pdf from 'vue-html2pdf';
import * as lang from 'vuejs-datepicker/src/locale';
import AppointmentsComments from './commentAppointments';
import optionsMixin from '../mixins/options';
// import PdfSocial from '../../pdfGenerate/socialAppointmentsPDF';
import dialogPatientList from '../../../components/dialogPatientListNew';
import Calendar from '../../../components/Calendar';
import citasCanceladasTable from '../../../components/citasCanceladasTable';
import PdftPrint from '../../../API/pdfPrint';
import CreatePatient from '../../../components/patient/createPatient';
import AppointmentAPI from '../../../API/appointments/index';
import EstudiosGabineteVue from '../../../components/EstudiosGabinete';
import socketStore from '../../../store/socket';
import { validateOldInformation } from '../../../API/patient';

const moment = require('moment');

export default {
  name: 'App',
  data() {
    return {
      needUpdate: false,
      action: 'create',
      cantCitas: 0,
      catalogoGabienteAgenda: [],
      showAppointmentGabinete: false,
      showGabineteModal: false,
      citasGabinete: [],
      citaGabineteId: null,
      triggerClear: false,
      dialogConfirmVisible: false,
      dialogConfirmBlockVisible: false,
      eyeSide: [
        {
          label: 'Ojo derecho',
          value: 'OD',
        },
        {
          label: 'Ojo izquierdo',
          value: 'OI',
        },
        {
          label: 'Ambos ojos',
          value: 'AO',
        },
      ],
      ruleForm: {
        nombre: '',
        procedimiento: '',
        tipo: null,
        tipoProcedimiento: null,
        eyeSide: null,
        notas: '',
      },
      rules: {
        nombre: { required: true, message: 'Campo obligatorio', trigger: 'blur' },
        tipo: { required: true, message: 'Campo obligatorio', trigger: 'change' },
      },
      procedimiento: null,
      tipo: null,
      pdfUrl: null,
      dialogImprimir: false,
      dialogCancelVisible: false,
      dialogCalendarEspecialidad: false,
      reservedHours: [],
      hour: null,
      date: null,
      currentRow: null,
      dialogPatientVisible: false,
      showModialPrint: false,
      especialidadLabel: 'retina',
      color: 'red',
      pacientes: [],
      timeout: null,
      bloquearHoraModal: false,
      // v-model variables
      especialidad: null,
      clase: 'Médico',
      gabineteProcedures: [],
      especialProcedimiento: null,
      // fecha, horarios, highlighted
      selectedDateStr: null,
      selectedDate: null,
      horarios: [],
      citas: [],
      blocked: [],
      citasCanceladas: [],
      languages: lang,
      highlighted: {
        daysOfMonth: [],
      },
      // Tipo de busqueda filtros
      claseAgenda: [
        {
          label: 'Consulta general',
          value: 'Médico',
        },
        {
          label: 'Especialidad',
          value: 'Especialidad',
        },
        {
          label: 'Estudios de Gabinete',
          value: 'Gabinete',
        },
      ],
      especialidades: [],
      tipoAgenda: [
        // {
        //   label: 'Estudios de Gabinete',
        //   value: 'CITA GABINETE',
        // },
        {
          label: 'Post-operatoría',
          value: 'POST OPERATORIA',
        },
        // {
        //   label: 'Emergencia',
        //   value: 'EMERGENCIA',
        // },
        {
          label: 'Primera vez',
          value: 'PRIMERA CITA',
        },
        {
          label: 'Subsecuente',
          value: 'CITA SUBSECUENTE',
        },
      ],
      cost:
        [
          {
            id: 1,
            value: 'sin costo',
            label: 'sin costo',
            color: '#FF6600',
          },
          {
            id: 2,
            value: 'con costo',
            label: 'con costo',
            color: '#008F39',
          },
          {
            id: 3,
            value: 'costo no definido',
            label: 'costo no definido',
            color: '#5555',
          },
          {
            id: 4,
            value: 'eliminar',
            label: 'eliminar costo',
          },
        ],
      crearCitaModal: false,
      crearCita: {},
      bloqueoHorario: {},
      especialidadNombre: null,
      procedimientoNombre: null,
      motivoBloqueo: null,
      statusOptions: [],
      statusValue: null,
      reason: null,
      citaSelected: null,
      showCancelReason: false,
      patientSelected: [],
      showRegisterPatient: false,
    };
  },
  mixins: [optionsMixin],
  permisoParaModificar: false,
  components: {
    Datepicker,
    AppointmentsComments,
    // PdfSocial,
    dialogPatientList,
    Calendar,
    VueHtml2pdf,
    citasCanceladasTable,
    CreatePatient,
    EstudiosGabineteVue,
  },
  computed: {
    ...mapState({
      lastSearch: (state) => state.app.lastSearch,
      typeSearch: (state) => state.app.typeSearch,
      lastRow: (state) => state.app.lastRow,
      role: (state) => state.auth.user.role,
    }),
  },
  methods: {
    confirm() {
      this.dialogConfirmVisible = false;
    },
    confirmUnblock(idx) {
      this.dialogConfirmBlockVisible = true;
      this.citaSelected = idx;
    },
    showGabinete(id) {
      if (this.clase === 'Gabinete') {
        this.citaGabineteId = id;
        this.crearCitaModal = false;
        this.showAppointmentGabinete = true;
      }
    },
    clearGabineteComponent() {
      this.triggerClear = !this.triggerClear;
      this.showGabineteModal = false;
      this.citaGabineteId = null;
    },
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.validateEnableAppointment();
        } else {
          return false;
        }
      });
    },
    callbackRegisterPatient(item) {
      this.showRegisterPatient = false;
      this.crearCitafirstAppointment = true;
      this.nombre = `${item.firstName} ${item.lastName}`;
      this.handleSelect({
        link: item.patientId,
        value: `${item.firstName} ${item.lastName} - ${item.patientId}`,
      });
    },
    callbackCancel() {
      this.tipo = '';
      this.showRegisterPatient = false;
    },
    changeCita() {
      if (this.ruleForm.tipo === 'PRIMERA CITA') {
        this.showRegisterPatient = true;
      }
    },
    async validateEnableAppointment() {
      try {
        const cita = {
          time: this.crearCita.hora,
          date: this.crearCita.dia,
          specialityId: this.especialidad,
          procedure: this.procedimiento,
          tipo: this.clase,
        };
        const response = await AppointmentAPI.getAppointmentValidation(cita);
        if (response === 'La hora está disponible' || this.action === 'update') {
          if (this.clase !== 'Gabinete') {
            await this.crearCitaDB();
          }
          if (this.clase === 'Gabinete') {
            await this.crearCitasGabineteDB(this.citasGabinete);
          }
        } else {
          this.$notify({
            title: 'Problema con el horario',
            message: response || 'El horario no está disponible',
            type: 'error',
          });
          this.crearCitaModal = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async imprimirPDF() {
      try {
        let title = null;
        if (this.clase === 'Médico') {
          title = 'Consulta general';
        } else if (this.clase === 'Gabinete') {
          title = `Estudios de Gabinete (${this.procedimiento})`;
        } else {
          title = this.especialidadNombre || 'Consulta general';
        }
        const dataCitas = {
          citas: this.citas,
          tipoAgenda: title,
        };
        const pdf = await PdftPrint.getPrintRecepcion(dataCitas, this.selectedDateStr);
        this.dialogImprimir = true;
        this.pdfUrl = pdf;
      } catch (error) {
        // this.$loading().close();
      }
    },
    async reagendarShow(row) {
      this.patientSelected = {
        ...row,
        firstAppointment: false,
      };
      await this.getReservedHours();
      this.dialogCalendarEspecialidad = true;
    },
    async getReservedHours() {
      try {
        if (this.date) {
          let response;
          const especialidad = this.patientSelected?.especialidadId || '';
          this.reservedHours = [];
          if (especialidad === '') {
            response = await this.axios(`patient/subsecuente/md/reserved-schedules?date=${this.date}`);
          } else {
            response = await this.axios(`patient/subsecuente/esp/reserved-schedules/${especialidad}?date=${this.date}`);
          }
          const { data } = response;
          if (Array.isArray(data)) this.reservedHours = data;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    selectedHour(value) {
      this.hour = value;
    },
    async selectedFecha(value) {
      this.date = value;
    },
    async handlerReagendar(seguir) {
      try {
        if (seguir) {
          const request = await this.axios.patch(
            `patient/subsecuente/md/reschedule/${this.patientSelected.idcita}`, {
              date: this.date,
              time: this.hour,
            },
          );
          if (request.status === 200) {
            const appointment = {
              ...this.patientSelected,
              date: this.date,
              time: this.hour,
            };

            this.$store.dispatch('updateAppointment', appointment);
            this.enviarCita({ ...appointment }, 'update');

            this.patientSelected = [];
            this.date = null;
            this.hour = null;
            this.dialogCalendarEspecialidad = false;
          }
        } else {
          this.dialogCalendarEspecialidad = false;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async openPatientInfo(item) {
      const { id, patientId } = item;
      const patient = await this.getPatientData(id || patientId);
      this.currentRow = {
        ...patient,
        id: id || patientId,
      };
      this.dialogPatientVisible = true;
    },
    selectAgeRange(birthdate) {
      const bd = moment(birthdate);
      const today = moment().now;
      const diff = Math.abs(bd.diff(today, 'years'));
      if (diff < 1) {
        return 'Menor de 1 Año';
      }
      if (diff < 6) {
        return '1 a 5 Años';
      }
      if (diff < 12) {
        return '6 a 11 Años';
      }
      if (diff < 18) {
        return '12 a 17 Años';
      }
      if (diff < 30) {
        return '18 a 29 Años';
      }
      if (diff < 60) {
        return '30 a 60 Años';
      }
      return '60 Años o mas';
    },
    async getPatientData(patientId) {
      try {
        let patient = {};
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        patient = data ? {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : this.patient;
        const response2 = await this.axios.get(`patient/profile/${patientId}`);
        const data2 = response2.data;
        patient = data2 ? {
          ...data,
          ...data2,
          fullName: `${data.firstName} ${data.lastName}`,
          fullAddress: `${data2.address}, \
            ${data2.city}, ${data2.state}, ${data2.country}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : patient;
        return patient;
      } catch (error) {
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        return {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        };
      }
    },
    async querySearchAsync(queryString, cb) {
      if (queryString === '') {
        cb([]);
        return;
      }
      await this.buscarPaciente(queryString);
      const { pacientes } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(pacientes);
      }, 100);
    },
    async handleSelect(item) {
      this.crearCita.nombrePaciente = item.value;
      this.crearCita.pacienteId = item.link;
      this.ruleForm.nombre = item.value;

      const needUpdateInfo = await validateOldInformation(item.link);
      if (needUpdateInfo?.requiresInfoCapture) {
        this.$notify({
          title: 'Información desactualizada',
          message: needUpdateInfo?.message || 'La información del paciente está desactualizada',
          type: 'warning',
        });
      }
      this.needUpdate = needUpdateInfo?.requiresInfoCapture;
    },
    async getFilterCitas() {
      try {
        let procedure = null;
        this.blocked = [];
        if (this.clase !== 'Médico' && this.clase !== 'Gabinete') {
          procedure = this.procedimientoNombre;
        } else {
          procedure = this.procedimiento;
        }

        if (this.clase === 'Gabiente') {
          this.tipo = 'Gabinete';
        }

        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        let tipos = this.tipo;
        const subject = this.clase;
        const specialty = this.especialidad;
        // const procedure = this.procedimiento;

        if (this.clase === 'Gabinete') {
          tipos = 'Gabinete';
          const request = await this.axios(`/patient/subsecuente/citas?type=${tipos}&subject=${subject}&procedure=${this.procedimiento}&date=${date}`);
          if (request.data) {
            this.citas = request.data;
            this.$store.dispatch('addAppointments', this.citas);
            await this.getBlocked(tipos, subject, specialty, date);
            // await this.hightlights(date);
          }
          this.crearHorarios(5, 20, 30);
        } else if (this.clase === 'Médico') {
          const request = await this.axios(`/patient/subsecuente/citas?type=${tipos}&subject=${subject}&procedure=${procedure}&specialty=${specialty}&date=${date}`);
          if (request.data) {
            this.citas = request.data;
            this.$store.dispatch('addAppointments', this.citas);
            await this.getBlocked(tipos, subject, specialty, date);
            await this.hightlights(date);
          }
          this.crearHorarios(5, 20, 10);
        } else if (this.clase === 'Especialidad') {
          const request = await this.axios(`/patient/subsecuente/citas?type=${tipos}&subject=${subject}&specialty=${specialty}&date=${date}`);
          if (request.data) {
            this.citas = request.data;
            this.$store.dispatch('addAppointments', this.citas);
            await this.getBlocked(tipos, subject, specialty, date);
            await this.hightlights(date);
          }
          this.crearHorarios(5, 20, 10);
        }
      } catch (error) {
        // this.$loading().close();
      }
    },
    async getFilterCitasCancel() {
      let procedure = null;
      this.citasCanceladas = [];
      if (this.clase === 'Médico' || this.clase === 'Gabinete') {
        procedure = this.procedimiento;
      } else {
        procedure = this.procedimientoNombre;
      }

      const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      const tipos = this.tipo;
      const subject = this.clase;
      const specialty = this.especialidad;
      // const procedure = this.procedimiento;

      const request = await this.axios(`/patient/subsecuente/citas/canceladas?type=${tipos}&subject=${subject}&procedure=${procedure}&specialty=${specialty}&date=${date}`);
      if (request) {
        this.citasCanceladas = request.data;
        this.dialogCancelVisible = true;
      }
    },
    async getBlocked() {
      try {
        this.blocked = [];
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const tipos = 'CITA SUBSECUENTE';
        const subject = this.clase;
        const specialty = this.especialidad;
        // const procedure = this.procedimiento;

        const request = await this.axios(`/patient/subsecuente/blockSchedule?type=${tipos}&date=${date}&subject=${subject}&specialty=${specialty}&procedure=${this.procedimiento}`);
        if (request.status === 200) {
          this.blocked = request.data;
        }
      } catch (error) {
        // console.error(error);
      }
    },
    crearHorarios(inicio, final, saltos) {
      let hour = inicio;
      const min = 60 - saltos;
      const hourFinal = final - 1;
      const horas = [];
      for (let minutos = 0; minutos <= min; minutos += saltos) {
        if (minutos === 0 || minutos === saltos) {
          if (hour <= 9) {
            horas.push({ hora: `0${hour}:00` });
          } else {
            horas.push({ hora: `${hour}:00` });
          }
        }
        if (minutos !== 0) {
          if (hour <= 9) {
            horas.push({ hora: `0${hour}:${minutos}` });
          } else {
            horas.push({ hora: `${hour}:${minutos}` });
          }
        }
        if (minutos === min && hour <= hourFinal - 1) {
          minutos = 0;
          hour++;
        }
      }
      if (hour === hourFinal) {
        horas.push({
          hora: `${hour + 1}:00`,
        });
      }
      horas.shift();
      this.horarios = horas;
      this.citaEnhorario();
    },
    citaEnhorario(action = null, cita = null) {
      if (action === 'delete') {
        const idx = this.horarios.findIndex((element) => element?.cita?.idcita === cita.id);

        this.horarios[idx] = {
          hora: this.horarios[idx].hora,
          cita: null,
          type: null,
        };
      } else if (action === 'update') {
        const hourIdx = this.horarios.findIndex((element) => element?.hora === cita?.time);
        const idx = this.horarios.findIndex((element) => element?.cita?.idcita === cita.idcita);

        this.horarios[idx] = {
          hora: this.horarios[idx]?.hora,
          cita: null,
          type: null,
        };

        this.horarios[hourIdx] = {
          hora: this.horarios[hourIdx]?.hora,
          cita,
          type: 'normal',
        };
      } else if (action === 'blockd') {
        const idx = this.horarios.findIndex((element) => element?.cita?.id === cita);

        this.horarios[idx] = {
          hora: this.horarios[idx].hora,
          cita: null,
          type: null,
        };

        this.blocked.forEach((element2) => {
          for (let i = 0; i < this.horarios.length; i++) {
            if (this.horarios[i].hora === element2.time) {
              this.horarios[i] = {
                hora: this.horarios[i].hora,
                cita: element2,
                type: 'blocked',
              };
            }
          }
        });
      } else {
        const app = this.$store.getters.appointments;

        app.forEach((element) => {
          for (let i = 0; i < this.horarios.length; i++) {
            if (this.horarios[i].hora === element.time) {
              this.horarios[i] = {
                hora: this.horarios[i].hora,
                cita: element,
                type: 'normal',
              };
            }
          }
        });

        this.blocked.forEach((element2) => {
          for (let i = 0; i < this.horarios.length; i++) {
            if (this.horarios[i].hora === element2.time) {
              this.horarios[i] = {
                hora: this.horarios[i].hora,
                cita: element2,
                type: 'blocked',
              };
            }
          }
        });
      }

      if (this.horarios.length > 0) {
        this.cantCitas += 1;
      }
    },
    async changedMonth(args) {
      const FORMAT = 'YYYY-MM-DD';
      let date = null;
      if (args.timestamp) {
        date = moment(new Date(args.timestamp)).format(FORMAT);
      } else {
        date = moment(args).format(FORMAT);
      }
      await this.hightlights(date);
    },
    async hightlights(date) {
      try {
        const tipos = this.tipo;
        const subject = this.clase;
        const specialty = this.especialidad;
        const procedure = null;
        this.highlighted.daysOfMonth = [];

        const param = moment(date, 'YYYY-MM-DD').format('YYYY/MM');
        const response = await this.axios(`patient/subsecuente/highlighted/v2/${param}?type=${tipos}&subject=${subject}&procedure=${procedure}&specialty=${specialty}`);
        if (response.data) {
          this.highlighted.daysOfMonth = response.data;
        }
      } catch (error) {
        // some code
      }
    },
    async buscarPaciente(fullName) {
      this.pacientes = [];
      const request = await this.axios(`/patient/list/v2?fullname=${fullName}&page=0`);
      request.data.map((res) => {
        this.pacientes.push({
          value: `${res.fullName} - ${res.patientId}`,
          link: res.patientId,
        });
        return null;
      });
    },
    agregarCita(idx) {
      this.action = 'create';

      this.ruleForm.tipo = null;
      this.ruleForm.procedimiento = null;
      this.ruleForm.notas = null;
      this.ruleForm.nombre = null;
      this.crearCitaModal = null;

      const horarioInfo = this.horarios[idx].hora;
      this.crearCita = {
        hora: horarioInfo,
        dia: this.selectedDateStr,
      };
      if (this.clase === 'Gabinete') {
        this.ruleForm.tipo = 'CITA SUBSECUENTE';
        this.showGabineteModal = true;
      }
      this.crearCitaModal = true;
    },
    handlerBloquear(idx) {
      this.bloquearHoraModal = true;
      this.bloqueoHorario = {
        horario: this.horarios[idx].hora,
      };
    },
    async bloquearHorario() {
      try {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const hour = this.bloqueoHorario.horario;
        const request = await this.axios({
          method: 'post',
          url: '/patient/subsecuente/blockSchedule',
          data: {
            hour,
            date,
            type: 'CITA SUBSECUENTE',
            procedure: this.procedimiento,
            specialty: this.especialidad,
            subject: this.clase,
            comments: this.motivoBloqueo,
          },
        });
        this.bloquearHoraModal = false;
        if (request) {
          this.enviarCita({
            date,
            time: hour,
            comments: this.motivoBloqueo,
          }, 'blockd');
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async desbloquearCita() {
      try {
        const citaId = this.horarios[this.citaSelected];
        const request = await this.axios.put(`patient/subsecuente/blockSchedule?citaId=${citaId.cita.id}`);
        if (request) {
          // this.$store.dispatch('refreshAppointments', socket);
          this.$notify({
            type: 'success',
            message: 'Horario desbloqueado con éxito',
          });
          // this.horarios[this.citaSelected] = {
          //   hora: this.horarios[this.citaSelected].hora,
          //   cita: null,
          //   type: null,
          // };

          this.enviarCita({
            id: this.horarios[this.citaSelected].cita.id,
            date: this.horarios[this.citaSelected].cita.date,
          }, 'blockd');
        }
        this.dialogConfirmBlockVisible = false;
        this.citaSelected = null;
      } catch (error) {
        // console.log(error);
      }
    },
    cerrarCita() {
      this.crearCita = {};
      this.ruleForm.notas = null;
      this.$refs?.ruleForm?.resetFields();
      this.crearCitaModal = false;
      this.needUpdate = false;
    },
    async crearCitaDB() {
      try {
        let procedures = null;
        let apitype = 'especialidad';
        let request = null;
        if (this.clase === 'Médico') {
          procedures = this.ruleForm.procedimiento;
          apitype = 'medico';
        } else if (this.clase === 'Gabinete') {
          procedures = this.ruleForm.procedimiento;
          apitype = 'gabinete';
        } else if (this.clase === 'Especialidad') {
          procedures = this.ruleForm.procedimiento;
        }
        const cita = {
          ...this.crearCita,
          type: this.ruleForm.tipo,
          comments: this.ruleForm.notas,
          procedure: this.ruleForm.procedimiento,
          especiality: this.especialidad,
          subject: this.clase,
          idcita: this.crearCita.cita?.idcita,
        };

        if (this.action === 'create') {
          request = await this.axios.post(`patient/subsecuente/${apitype}/${cita.pacienteId}`, {
            type: cita.type,
            location: null,
            subject: cita.subject, // medico(consulta gral.), especialidad, Gabinete
            date: cita.dia, // fecha
            time: cita.hora, // hora
            specialityId: cita.especiality ?? null,
            procedure: cita.procedure, // procedimiento
            comments: cita.comments, // comentarios
            firstAppointment: cita.firstAppointment ?? false,
          });
        } else if (apitype === 'gabinte' && this.action === 'update') {
          // LOGICA PARA ACTUALIZAR CITA DE GABINETE
        } else if (apitype !== 'gabinete' && this.action === 'update') {
          request = await this.axios.put(`patient/subsecuente/citas/${cita.idcita}`, {
            type: cita.type,
            specialityId: cita.especiality ?? null,
            procedure: cita.procedure, // procedimiento
            comments: cita.comments, // comentarios
          });
        }
        if (request) {
          const appointment = {
            ...this.crearCita.cita,
            patientId: cita.pacienteId,
            type: cita.type,
            location: null,
            cancelReason: null,
            subject: cita.subject, // medico(consulta gral.), especialidad, Gabinete
            date: cita.dia, // fecha
            time: cita.hora, // hora
            specialityId: cita.especiality ?? null,
            // eslint-disable-next-line max-len
            especialidad: this.especialidades.find((x) => x.value === cita.especiality)?.label || null,
            procedure: cita.procedure, // procedimiento
            comments: cita.comments, // comentarios
            firstAppointment: cita.firstAppointment ?? false,
            idcita: request?.data?.response?.id,
          };
          this.cerrarCita();
          this.enviarCita(appointment, 'create');
          this.$notify({
            type: 'success',
            message: 'Cita creada con éxito',
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async crearCitasGabineteDB(citaGB) {
      const cita = {
        ...this.ruleForm,
        ...this.crearCita,
        type: this.ruleForm.tipo,
        procedure: this.procedimiento,
        citas: citaGB.citas,
      };
      try {
        const request = await this.axios.post('patient/gabineteAppointment', {
          type: cita.type,
          subject: 'Gabinete', // medico(consulta gral.), especialidad, Gabinete
          date: cita.dia, // fecha
          time: cita.hora, // hora
          patientId: cita.pacienteId,
          procedure: cita.procedure, // procedimiento
          comments: cita.notas, // comentarios
          firstAppointment: cita.firstAppointment ?? false,
          citas: cita.citas,
        });
        if (request) {
          const appointment = {
            ...cita,
            idcita: request?.data?.response?.id,
            subject: 'Gabinete',
            firstAppointment: cita.firstAppointment ?? false,
            comments: cita.notas,
            specialityId: cita.especiality ?? null,
            date: cita.dia, // fecha
            time: cita.hora, // hora
          };
          this.cerrarCita();
          this.enviarCita(appointment, 'create');
          this.clearGabineteComponent();
          this.$notify({ type: 'success', message: 'Cita creada con éxito' });
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error al crear la cita' });
      }
    },
    crearCitaGabinete(values) {
      const cita = {
        citas: [],
        ...this.crearCita,
        type: this.ruleForm.tipo,
        procedure: this.clase,
      };
      values.forEach((element) => {
        cita.citas.push({
          speciality_id: element.especialidad,
          type_procedure_id: element.procedimiento || null,
          eyeSide: element.eyeSide,
          equipoId: element.equipoId, // es equipo
          gabinete_id: element.estudioId, // es gabinete
        });
      });
      this.citasGabinete = cita;
    },
    async getStatuses() {
      try {
        this.loading = true;
        const response = await this.axios.get('patient/status/');
        const { data } = response;
        this.statusOptions = data;
      } catch (error) {
        this.$errorNotify(error);
      } finally {
        this.loading = false;
      }
    },
    async onStatusChange(statusId, citaId, idx) {
      try {
        let response = null;
        const statusObj = this.statusOptions.find((x) => x.id === statusId);
        const { colorCode } = this.horarios[idx].cita;
        const patientId = this.horarios[idx].cita.id;
        if (colorCode !== null) {
          response = await this.axios.put(`patient/status/${patientId}/${citaId}`, {
            statusId,
          });
        } else {
          response = await this.axios.post(`patient/status/${patientId}/${citaId}`, {
            statusId,
          });
        }
        if (response) {
          this.$notify({
            type: 'success',
            message: 'Estado actualizado con éxito',
          });
          this.horarios[idx].cita.status = statusObj.status;
          this.horarios[idx].cita.statusId = statusObj.id;
          this.horarios[idx].cita.colorCode = statusObj.colorCode;
        }
      } catch (err) {
        this.$errorNotify(err);
      }
    },
    async onCostChange(citaId, idx) {
      try {
        const costStatus = this.horarios[idx].cita.cost === 'eliminar' ? null : this.horarios[idx].cita.cost;
        const request = await this.axios.put(`/patient/subsecuente/updatecost/${citaId}`, {
          idcita: citaId,
          cost: costStatus,
        });
        if (request) {
          this.$notify({
            type: 'success',
            message: 'Costo actualizado con éxito',
          });

          if (costStatus === null) {
            this.horarios[idx].cita.cost = null;
          } else {
            this.horarios[idx].cita.createdByCostLastName = this.$store.state.auth.user.lastName;
            this.horarios[idx].cita.createdByCost = this.$store.state.auth.user.firstName;
          }
        }
      } catch (err) {
        this.$errorNotify(err);
      }
    },
    async getAllSpecialities() {
      try {
        const request = await this.axios('patient/specialty/all/v2');
        const { data } = request;
        if (request) {
          // this.cards.push(...data);
          data.forEach((element) => {
            this.especialidades.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    cancelarShow(citaId) {
      this.showCancelReason = true;
      this.citaSelected = citaId.idcita;
    },
    confirmarShow(citaId) {
      this.citaSelected = citaId.idcita;
      this.dialogConfirmVisible = true;
    },
    async cancel() {
      try {
        this.loading = true;
        const cancelString = this.reason;
        const request = await this.axios.patch(`patient/subsecuente/md/cancel/${this.citaSelected}`, {
          cancelReason: cancelString,
        });
        if (request) {
          this.$store.dispatch('delAppointment', this.citaSelected);
          this.enviarCita({
            id: this.citaSelected,
            cancelReason: cancelString || null,
            date: this.selectedDateStr,
            subject: this.clase,
            specialityId: this.especialidad || null,
          }, 'delete');
          this.$notify({
            type: 'success',
            message: 'Cancelación realizada con éxito',
          });
        }
        this.citaSelected = null;
        this.reason = null;
        this.showCancelReason = false;
        this.loading = false;
      } catch (err) {
        this.$errorNotify(err);
      } finally {
        this.loading = false;
        this.reason = null;
      }
    },
    async emergenciaHandler(action) {
      if (action === 'cancel') {
        this.ruleForm.tipo = null;
        this.procedure = null;
        this.procedimientoNombre = null;
        this.cerrarCita();
        await this.getFilterCitas();
      } else {
        this.tipo = 'EMERGENCIA';
        this.getFilterCitas();
      }
      // this.crearCitaModal = true;
    },
    socketsOn() {
      socketStore.dispatch('socketEmit', {
        event: 'join_room',
        room: 'recepcion',
      });

      socketStore.dispatch('socketOn', {
        event: 'appointments',
        room: 'recepcion',
        callback: (data) => {
          this.addAppointmentSocket({
            appointment: data.appointment,
            action: data.action,
            message: data.message,
          });
        },
      });

      socketStore.dispatch('socketOn', {
        event: 'blockSchedule',
        room: 'recepcion',
        callback: (data) => {
          this.blocked.push(data);
        },
      });
    },
    enviarCita(appointment, action) {
      const userStorage = JSON.parse(localStorage.getItem('vuex'));
      const userName = `${userStorage.auth.user.firstName} ${userStorage.auth.user.lastName}`;

      const actionsTypes = {
        create: 'ha creado una nueva cita.',
        update: 'ha actualizado una cita.',
        blockd: 'ha bloqueado un horario.',
        delete: 'ha cancelado una cita.',
      };

      if (action === 'blockd' || action === 'unblockd') {
        socketStore.dispatch('socketEmit', {
          event: 'blockSchedule',
          room: 'social',
          data: {
            message: actionsTypes[action] || 'ha creado una nueva cita.',
            action,
            user: userName,
            appointment,
          },
        });
      } else {
        socketStore.dispatch('socketEmit', {
          event: 'appointments',
          room: 'recepcion',
          data: {
            message: actionsTypes[action] || 'Ha creado una nueva cita.',
            action,
            user: userName,
            appointment,
          },
        });
      }
    },
    editarCita(cita) {
      this.action = 'update';
      this.crearCita = {
        ...this.horarios[cita],
        dia: this.horarios[cita].cita.date,
        hora: this.horarios[cita].cita.time,
        type: this.horarios[cita].cita.type,
        procedure: this.horarios[cita].cita.procedure,
        comments: this.horarios[cita].cita.comments,
        nombrePaciente:
          `${this.horarios[cita].cita.nombrePaciente} ${this.horarios[cita].cita.apellidoPaciente} - ${this.horarios[cita].cita.id}`,
      };
      this.ruleForm.tipo = this.crearCita.type;
      this.ruleForm.procedimiento = this.crearCita.procedure;
      this.ruleForm.notas = this.crearCita.comments;
      this.ruleForm.nombre = this.crearCita.nombrePaciente;
      this.crearCitaModal = true;
    },
    addAppointmentSocket(data) {
      const { appointment, action, message } = data;

      const date = this.selectedDateStr;

      const codition = (
        (appointment?.date || appointment?.dia) === date
        && appointment?.subject === this.clase
        // eslint-disable-next-line max-len
        && (((appointment?.specialityId || null) === this.especialidad) || (appointment?.procedure === this.procedimiento))
      ) || (action === 'blockd' && appointment.date === date);

      if (codition) {
        if (action === 'create') {
          this.$store.dispatch('setStateAppointment', appointment);
          const app = this.$store.getters.appointments;
        } else if (action === 'delete') {
          this.$store.dispatch('delAppointment', appointment.id);
        } else if (action === 'update') {
          this.$store.dispatch('updateAppointment', appointment);
        } else if (action === 'blockd') {
          this.blocked.push(appointment.id);
        }

        this.citaEnhorario(action || 'create', action === 'blockd' ? appointment.id : appointment);

        this.$notify({
          title: 'Notificación',
          message,
          type: action === 'create' ? 'success' : 'info',
        });
      }
    },
    imprimirCitasCanceladas() {
      this.$refs.html2Pdf.generatePdf();
    },
    obtenerPermisos() {
      const permisos = JSON.parse(localStorage.getItem('vuex'));
      if (permisos.auth.user.roleId === 'MD' || permisos.auth.user.roleId === 'OPTO') {
        this.permisoParaModificar = false;
      } else {
        this.permisoParaModificar = true;
      }
    },
    closeDialog(clearHistory) {
      this.dialogPatientVisible = false;
      if (clearHistory) {
        this.$store.dispatch('app/setLastRow', {});
      }
    },
    handleClose(done) {
      this.dialogPatientVisible = false;
      this.needUpdate = false;
      this.$store.dispatch('app/setLastRow', {});
      if (this.clase === 'Gabinete') {
        this.clearGabineteComponent();
      }
      done();
    },
    async getAllProceduresGabinete() {
      try {
        const gabineteProcedures = localStorage.getItem('gabinete');
        if (gabineteProcedures) {
          const procedimientos = JSON.parse(gabineteProcedures);
          procedimientos.forEach((element) => {
            this.gabineteProcedures.push({
              label: element.name,
              value: element.id,
            });
          });
          return;
        }
        const request = await this.axios('patient/gabinete/all');
        const { data } = request;
        if (request) {
          localStorage.setItem('gabinete', JSON.stringify(data));
          data.forEach((element) => {
            this.gabineteProcedures.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async mounted() {
    this.pacientes = [];
  },
  async created() {
    this.obtenerPermisos();
    this.selectedDate = moment().format();
    this.getStatuses();
    this.catalogoGabienteAgenda = JSON.parse(localStorage.getItem('gabineteAgenda'));
    await this.getAllSpecialities();

    this.socketsOn();
  },
  beforeDestroy() {
    // disconnect to room
    // socket.off('REFRESH_APPOINTMENTS', 'recepcion');
    // socket.emit('disconnect to room', 'recepcion');
    socketStore.dispatch('socketEmit', {
      event: 'leave room',
      room: 'recepcion',
      data: {
        message: 'Ha salido de la sala de recepción',
      },
    });
  },
  watch: {
    async selectedDate(val) {
      if (val) {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.selectedDateStr = date;
        this.blocked = [];
        await this.getFilterCitas();
      }
    },
    async clase() {
      this.especialidad = null;
      this.especialidadNombre = null;
      await this.getFilterCitas();
    },
    nombre(val) {
      if (val.length === 0) {
        this.crearCita.nombrePaciente = null;
      }
    },
    especialidad(val) {
      this.especialidadNombre = this.especialidades[val - 1].label;
    },
    date() {
      this.getReservedHours();
    },
  },
};
</script>

<style lang="scss" scoped>
  .busqueda {
    width: 100%;
    text-align: center;
    padding: 15px;
  }
  .title {
    color: #6a41bdca;
  }
  .selects-agendas {
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }
  label {
    color: #6a41bdca;
    font-weight: bold;
  }
  #label-hora {
    color:#6a41bdb4;
    font-size: 16px;
    font-weight: bold;
  }
  .block-hour {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    strong {
      color: rgb(55, 55, 55);
    }
    button {
      border: none;
    }
  }
  #button-fundacionLuz {
    background-color: #9477d0;
    color: #fff;
    border: none;
    border-radius: 8px
  }
  .opciones-horario {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 33px;
  }
  .dialog-block {
    padding: 15px;
  }
  #fecha-title {
    font-size: 1em;
    margin: 0;
    color: #6a41bdb4;
    font-weight: bold;
  }
  .opciones {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
  }
</style>
