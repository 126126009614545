<template>
  <div class="optionsAppointments">
    <div class="comment-container">
      <label id="comments-text" v-if="!modifyComment">
        {{ commentLocal }}
      </label>
      <div style="display:flex; flex-direction:column;">
        <el-input
          v-if="modifyComment"
          type="textarea"
          :rows="2"
          v-model="commentLocal"
        />
        <div style="padding: 5px 0px 5px 0px;" v-if="modifyComment">
          <el-tooltip
            content="Editar"
            placement="top"
          >
            <el-button
              type="success"
              icon="el-icon-check"
              size="small"
              style="border: none;"
              @click="submitEditComment()"
              circle
            />
          </el-tooltip>
          <el-tooltip
            content="Cancelar"
            placement="top"
          >
            <el-button
              size="small"
              style="border: none;"
              type="danger"
              icon="el-icon-close"
              @click="modifyComment = !modifyComment"
              circle
            />
          </el-tooltip>
        </div>
      </div>
      <div v-if="permisoParaModificar">
        <el-tooltip
          content="Editar comentario"
          placement="top"
          v-if="!modifyComment"
        >
          <el-button
            size="small"
            style="border: none; background: #0001;"
            icon="el-icon-edit"
            @click="modifyComment = !modifyComment"
            circle
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comments: {
      type: String,
      default: '',
    },
    citaId: {
      type: Number,
      default: 0,
    },
    typeAppointment: {
      type: String,
      default: 'updatecomment',
    },
    permisoParaModificar: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modifyComment: false,
      commentLocal: null,
    };
  },
  methods: {
    async submitEditComment() {
      try {
        const request = await this.axios.put(`/patient/subsecuente/${this.typeAppointment}/${this.citaId}`, {
          comment: this.commentLocal,
        });
        if (request.status === 200) {
          this.$notify({
            type: 'success',
            message: 'Comentario actualizado con éxito',
          });
          this.modifyComment = false;
        }
      } catch (error) {
        this.$errorNotify(error);
      } finally {
        this.commentFormLoading = false;
      }
    },
  },
  created() {
    this.commentLocal = this.comments;
  },
  watch: {
    comments() {
      this.commentLocal = this.comments;
    },
  },
};
</script>

<style scoped lang="scss">
.optionsAppointments {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
}
.comment-container {
  display: flex;
  justify-content: right;
  width: 250px;
  font-size: 14px;
  label {
    padding: 5px;
  }
}
</style>
