<template>
  <div class="typeSwitchAppointment">
    <el-form>
      <el-form-item :label="`${mostrar}`" prop="delivery">
        <el-switch v-model="typeAgenda" @change="changeTitle()"/>
      </el-form-item>
    </el-form>
    <agendaMedica v-if="!typeAgenda" />
    <agendaSocial v-if="typeAgenda" />
  </div>
</template>

<script>
import agendaMedica from './appointments/index';
import agendaSocial from './appointments/indexSocial';

export default {
  data() {
    return {
      typeAgenda: false,
      mostrar: 'Agenda Médica',
    };
  },
  components: {
    agendaMedica,
    agendaSocial,
  },
  methods: {
    changeTitle() {
      if (this.mostrar === 'Agenda Médica') {
        this.mostrar = 'Agenda de trabajo social';
      } else {
        this.mostrar = 'Agenda Médica';
      }
    },
  },
};
</script>

<style lang="scss">
.typeSwitchAppointment {
  padding: 10px;

  .el-switch.is-checked .el-switch__core {
    background-color: #5715879e;
    border: #000;
  }
}
</style>
