/* eslint-disable consistent-return */
import http from '../index';

const BASEURI = 'patient/appointment';
const BASEURI_GABINETE = 'patient/gabineteAppointment';

const AppointmentAPI = {
  // Validar si la cita esta disponible
  getAppointmentValidation: async (data) => {
    try {
      const request = await http.post(`${BASEURI}/isEnableHour`, {
        date: data.date,
        time: data.time,
        specialityId: data.specialityId,
        procedure: data.procedure,
        tipo: data.tipo,
      });
      if (request) return request.data.message;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // Validar si el paciente existe
  getPatientExist: async (data) => {
    try {
      const { firstName, lastName, birthdate } = data;
      const fechaNacimient = birthdate.toString();
      const request = await http.post(`patient/specialty/patientExist/${firstName}/${lastName}/${fechaNacimient}`);
      if (request) {
        return request.data.message;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // guardar cita de gabinete
  postGabineteAppointment: async (data) => {
    try {
      const request = await http.post(`${BASEURI}`, data);
      if (request) {
        return request.data.message;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // guardar cita de especialidad
  getAppointmentById: async (data) => {
    try {
      const request = await http.get(`${BASEURI_GABINETE}/${data}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default AppointmentAPI;
