<template>
  <div>
    <h4><label>{{ action }}</label> Estudios de Gabinete</h4>
    <el-form
      v-if="!showAppointmentGabinete"
      label-position="top"
      :model="ruleForm"
      :rules="rules" ref="ruleForm"
      >
      <el-row>
        <!-- <el-col :span="4">
          <el-form-item label="Especialidad" prop="especialidad">
            <el-select v-model="ruleForm.especialidad" placeholder="Seleccionar">
              <el-option
                v-for="item in especialidades"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="5">
          <el-form-item label="Estudio" prop="procedimiento">
            <el-select v-model="ruleForm.procedimiento" placeholder="Seleccionar" >
              <el-option
                v-for="item in gabineteProcedures"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5" v-if="procedureTypes[ruleForm.procedimiento]">
          <el-form-item label="Procedimiento" prop="type_procedure_id">
            <el-select v-model="ruleForm.type_procedure_id" placeholder="Seleccionar" >
              <el-option
                v-for="item in procedureTypes[ruleForm.procedimiento]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="Equipo" prop="equipoId">
            <el-select v-model="ruleForm.equipoId" placeholder="Seleccionar" >
              <el-option
                v-for="item in equipos"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="Lado de ojo" prop="eyeSide">
            <el-select
              v-model="ruleForm.eyeSide"
              placeholder="Seleccionar"
            >
              <el-option
                v-for="item in eyeSide"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button
            @click="submitForm('ruleForm')"
            style="
              background-color:rgb(148, 119, 208);color:#fff;
              margin-top:50px;padding:12px;
              width:50px;border:none">
             <i class="el-icon-plus" />
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="appointments" style="margin:20px 0 20px 0;">
      <el-table-column prop="estudioName" label="Estudio" />
      <el-table-column prop="procedimientoName" label="Procedimiento" />
      <el-table-column prop="equipoName" label="Equipo" />
      <el-table-column prop="eyeSide" label="Ojo" />
      <el-table-column v-if="!showAppointmentGabinete" fixed="right" label="Operaciones">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small">
            Eliminar
            <i class="el-icon-delete" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AppointmentAPI from '../API/appointments/index';
import API_GABINETE from '../API/globales/estudios';

export default {
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    appointmentId: {
      type: Number,
      default: null,
    },
    showAppointmentGabinete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // especialidades: [],
      gabineteProcedures: [],
      gabineteProceduresType: [],
      appointments: [],
      appointmentSelected: null,
      action: null,
      procedureTypes: [],
      equipos: [],
      eyeSide: [
        {
          label: 'OJO DERECHO',
          value: 'OD',
        },
        {
          label: 'OJO IZQUIERDO',
          value: 'OI',
        },
        {
          label: 'AMBOS OJOS',
          value: 'AO',
        },
      ],
      ruleForm: {
        procedimiento: null,
        eyeSide: null,
        // especialidad: null,
        type_procedure_id: null,
      },
      rules: {
        procedimiento: [{ required: true, message: 'Este campo es requerido.', trigger: 'change' }],
        eyeSide: [{ required: true, message: 'Este campo es requerido.', trigger: 'change' }],
        especialidad: [{ required: true, message: 'Este campo es requerido.', trigger: 'change' }],
        type_procedure_id: [{ required: true, message: 'Este campo es requerido.', trigger: 'change' }],
        equipoId: [{ required: true, message: 'Este campo es requerido.', trigger: 'change' }],
      },
    };
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deleteRow(row) {
      this.appointments.splice(row, 1);
    },
    clearTable() {
      this.appointments = [];
      this.appointmentSelected = null;
      this.action = null;
    },
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) this.addAppointment();
        return false;
      });
    },
    addAppointment() {
      const appointment = {
        procedimiento: this.ruleForm.type_procedure_id,
        estudioId: this.ruleForm.procedimiento || null,
        estudioName: this.gabineteProcedures.find(
          (element) => element.value === this.ruleForm.procedimiento,
        ).label,
        eyeSide: this.ruleForm.eyeSide || null,
        procedimientoName: this.procedureTypes[this.ruleForm.procedimiento]?.find(
          (element) => element.value === this.ruleForm.type_procedure_id,
        )?.label || 'N/A',
        equipoId: this.ruleForm.equipoId || null,
        equipoName: this.equipos.find(
          (element) => element.value === this.ruleForm.equipoId,
        ).label.toUpperCase(),
      };
      this.appointments.push(appointment);
      this.$emit('appointments-gabinete', this.appointments);
      this.resetForm('ruleForm');
    },
    async getAllProceduresGabinete() {
      try {
        const gabineteProcedures = localStorage.getItem('gabinete');
        if (gabineteProcedures) {
          const procedimientos = JSON.parse(gabineteProcedures);
          procedimientos.forEach((element) => {
            this.gabineteProcedures.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getAllEquiposGabinete() {
      try {
        const equiposGabinete = localStorage.getItem('equipos');
        if (equiposGabinete) {
          const procedimientos = JSON.parse(equiposGabinete);
          procedimientos.forEach((element) => {
            this.equipos.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getTiposEstudios() {
      try {
        const request = await API_GABINETE.getAllEstudios();
        if (request) {
          const estudios = [];

          request.forEach((value) => {
            estudios.push({
              value: value.id,
              label: value.name,
              type_procedure_id: value.type_procedure_id,
            });
          });

          // Utilizamos reduce para transformar el array en un objeto agrupado por 'belongs'
          const grouped = estudios.reduce((accumulator, item) => {
            // Verifica si el acumulador ya tiene una propiedad para este 'belongs'
            if (!accumulator[item.type_procedure_id]) {
              // eslint-disable-next-line no-param-reassign
              accumulator[item.type_procedure_id] = [];
            }
            // Agrega el objeto actual al array correspondiente
            accumulator[item.type_procedure_id].push({
              label: item.label,
              value: item.value,
            });
            return accumulator;
          }, {});
          this.procedureTypes = grouped;
          // console.log(this.procedureTypes);
        }
      } catch (error) {
        // console
      }
    },
    async getAppointmentById() {
      try {
        const request = await AppointmentAPI.getAppointmentById(this.appointmentId);
        const data = request;

        if (request) {
          const dataParsed = [];

          data.forEach((element) => {
            dataParsed.push({
              estudioName: element?.gabinete.name || 'N/A',
              equipoName: element?.equipo === null ? 'N/A' : element?.equipo.name,
              procedimientoName: element.gabineteTypeProcedure?.name || 'N/A',
              procedureTypesName: element?.gabineteTypeProcedure?.name || 'N/A',
              eyeSide: element.eyeSide,
            });
          });
          this.appointments = dataParsed;
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async created() {
    // this.getEspecialidades();
    this.getAllProceduresGabinete();
    this.getAllEquiposGabinete();
    this.appointmentSelected = this.appointmentId;
    this.action = this.appointmentId ? 'Visualizando' : 'Creando';
    this.getTiposEstudios();
    if (this.appointmentId) {
      await this.getAppointmentById();
    }
  },
  watch: {
    clear(value) {
      if (value) {
        this.clearTable();
      }
    },
  },
};
</script>
